import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { motion } from "framer-motion"

const Wrapper = styled.div`
  margin: 20px auto;
`

const Message = styled.div`
  text-align: center;
  margin-top: 0.6em;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.06em;
  color: #333;
`

const Bubbles = styled.div`
  display: grid;
  grid-template-columns: 30px 30px 30px;
  width: 90px;
  margin: 0 auto;
`

const Bubble = styled(motion.div)`
  width: 30px;
  height: 30px;
  border: 5px solid #eee;
  border-radius: 50%;
  background: linear-gradient(
    270deg,
    #22b8ef 0%,
    #8a79f0 51.56%,
    #ed63fd 100%,
    #ed63fd 100%
  );
`

const AnimatedBubble = ({ delay }) => (
  <Bubble
    initial={{ scale: 0, opacity: 1 }}
    animate={{ scale: [0, 1], opacity: [1, 0] }}
    transition={{
      repeat: Infinity,
      repeatType: "loop",
      duration: 1,
      delay,
    }}
  />
)

const Loader = ({ messages, treshold }) => {
  const [index, setIndex] = React.useState(0)

  React.useEffect(() => {
    if (messages) {
      const timer = setInterval(
        () => setIndex(Math.min(index + 1, messages.length - 1)),
        treshold * 1000
      )

      return () => {
        clearTimeout(timer)
      }
    }
  }, [messages, treshold, index])

  return (
    <Wrapper>
      <Bubbles>
        <AnimatedBubble delay={0.7} />
        <AnimatedBubble delay={0} />
        <AnimatedBubble delay={0.3} />
      </Bubbles>
      {messages && <Message>{messages[index]}</Message>}
    </Wrapper>
  )
}

export default Loader

Loader.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string),
  treshold: PropTypes.number,
}
