import React from "react"
import { useWallet } from "@solana/wallet-adapter-react"
import { useQuery } from "react-query"
import { getMe } from "@actions/user"
import { useUser } from "@contexts/user"
import { CookieBanner } from "@bw/partials"
import { Header, Footer } from "@bw/layouts"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import Logo from "../../../images/logo-NFT4artists"
import LogoMobile from "../../../images/logo-NFT4artists-mobile"
import { Seo } from "@bw/bits"
import { ResponsiveContext } from "grommet"
import { breakpoints } from "../../../theme"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  overflow-x: hidden;
  background: ${props => props.$background};
`

const Gradients = styled.div`
  position: absolute;
  top: 45vh;
  right: -20%;

  ::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -10%;
    width: 917px;
    height: 655px;
    background: radial-gradient(
      47.85% 47.85% at 50% 52.15%,
      rgba(71, 155, 189, 0.42) 0%,
      rgba(196, 196, 196, 0) 100%
    );
    opacity: 0.6;
    transform: matrix(0.96, -0.11, -0.31, -0.99, 0, 0);
  }

  ::before {
    content: "";
    position: absolute;
    top: 50%;
    right: -20%;
    width: 794px;
    height: 592px;

    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(96, 128, 233, 0.32) 0%,
      rgba(196, 160, 209, 0.115) 64.06%,
      rgba(196, 196, 196, 0) 100%
    );
    transform: matrix(-0.02, 1.01, 0.98, 0.13, 0, 0);
  }
`

const StyledMain = styled.main`
  position: relative;
  padding: 90px 0 50px;
  width: 100%;
  height: auto;
  overflow: hidden;
`

const MainContainer = styled.div`
  position: relative;
  z-index: 1;
`

const StyledLogoMobile = styled(LogoMobile)`
  display: none;
  @media (max-width: ${breakpoints.small}px) {
    display: block;
  }
`

const StyledLogo = styled(Logo)`
  display: block;
  @media (max-width: ${breakpoints.small}px) {
    display: none;
  }
`

const LogoWrapper = styled(Link)`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: auto;
  }
`
const Logos = ({ mode }) => {
  const LogoResponsive = mode === "desktop" ? StyledLogo : StyledLogoMobile
  return <LogoResponsive />
}

const Layout = ({ children, background, pageContext }) => {
  const size = React.useContext(ResponsiveContext)
  const { disconnect } = useWallet()
  const [user, dispatch] = useUser()

  useQuery(`me_${user.publicKey}_${user.loggedIn ? "login" : "logout"}`, () => {
    return user.loggedIn ? getMe(dispatch, disconnect) : null
  })

  return (
    <>
      <CookieBanner />
      <Wrapper $background={background}>
        <Seo lang={pageContext.locale} />
        <Header
          {...{ pageContext }}
          logo={
            <LogoWrapper to="/">
              <Logos mode={size === "small" ? "mobile" : "desktop"} />
            </LogoWrapper>
          }
        />

        <Gradients />
        <StyledMain>
          <MainContainer>{children}</MainContainer>
        </StyledMain>
        <Footer />
      </Wrapper>
    </>
  )
}

export default Layout
