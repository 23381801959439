import React from "react"
import PropTypes from "prop-types"

const IconLike = ({ fillColor, strokeColor }) => (
  <svg
    width="16"
    height="15"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 5.01818C1 1.94545 3.40625 1 4.71875 1C6.03125 1 7.34375 1.70909 8 2.89091C8.65625 1.70909 9.53125 1 11.0625 1C12.5938 1 15 2.18182 15 5.01818C15 7.85455 10.8438 12.1091 8 14C5.375 12.3455 1 7.61818 1 5.01818Z"
      fill={fillColor}
      strokeWidth="2"
      stroke={strokeColor}
      strokeLinejoin="round"
    />
  </svg>
)

export default IconLike

IconLike.propsTypes = {
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
}

IconLike.defaultProps = {
  strokeColor: "#6080E9",
}
