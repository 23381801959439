import React from "react"
import PropTypes from "prop-types"

const IconWallet = ({ fillColor }) => (
  <svg
    width="34"
    height="25"
    viewBox="0 0 34 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.9036 15.3448C24.9044 15.3448 25.7157 14.5343 25.7157 13.5345C25.7157 12.5347 24.9044 11.7241 23.9036 11.7241C22.9027 11.7241 22.0914 12.5347 22.0914 13.5345C22.0914 14.5343 22.9027 15.3448 23.9036 15.3448Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.8579 2.06897V1.55172C29.8579 0.694731 29.1624 0 28.3046 0H1.5533C0.695436 0 0 0.694731 0 1.55172V3.1419H0.0753494C0.0264331 3.2927 0 3.45361 0 3.62069V23.4483C0 24.3053 0.695436 25 1.5533 25H30.203C31.0609 25 31.7563 24.3053 31.7563 23.4483V18.2759H32.7398C33.4358 18.2759 34 17.7122 34 17.0169V10.052C34 9.35675 33.4358 8.7931 32.7398 8.7931H31.7563V3.62069C31.7563 2.7637 31.0609 2.06897 30.203 2.06897H29.8579ZM28.3046 1.2069H1.5533C1.36266 1.2069 1.20812 1.36128 1.20812 1.55172V2.10742C1.31913 2.08225 1.43466 2.06897 1.5533 2.06897H28.6497V1.55172C28.6497 1.36128 28.4952 1.2069 28.3046 1.2069ZM1.5533 3.27586H30.203C30.3937 3.27586 30.5482 3.43025 30.5482 3.62069V8.7931H23.9036C21.2823 8.7931 19.1574 10.9159 19.1574 13.5345C19.1574 16.1531 21.2823 18.2759 23.9036 18.2759H30.5482V23.4483C30.5482 23.6387 30.3937 23.7931 30.203 23.7931H1.5533C1.36266 23.7931 1.20812 23.6387 1.20812 23.4483V3.62069C1.20812 3.43025 1.36266 3.27586 1.5533 3.27586ZM23.9036 10H32.7398C32.7685 10 32.7919 10.0233 32.7919 10.052V17.0169C32.7919 17.0457 32.7685 17.069 32.7398 17.069H23.9036C21.9495 17.069 20.3655 15.4865 20.3655 13.5345C20.3655 11.5824 21.9495 10 23.9036 10Z"
      fill={fillColor}
    />
  </svg>
)

export default IconWallet

IconWallet.propsTypes = {
  fillColor: PropTypes.string,
}

IconWallet.defaultProps = {
  fillColor: "#6080E9",
}
