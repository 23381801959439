import React from "react"
import PropTypes from "prop-types"

const IconLinkedin = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4.40165C2 3.07583 3.07583 2 4.40166 2C5.72665 2 6.80248 3.07583 6.80331 4.40165C6.80331 5.72748 5.72748 6.82581 4.40166 6.82581C3.07583 6.82581 2 5.72748 2 4.40165Z"
      fill={fillColor}
    />
    <path
      d="M21.9999 21.9992V14.6642C21.9999 11.0759 21.2274 8.3117 17.0324 8.3117C15.0158 8.3117 13.6624 9.41837 13.1099 10.4675H13.0516V8.6467H9.07411V21.9992H13.2158V15.3875C13.2158 13.6467 13.5458 11.9634 15.7016 11.9634C17.8258 11.9634 17.8574 13.95 17.8574 15.4992V22L21.9999 21.9992Z"
      fill={fillColor}
    />
    <path
      d="M2.32994 8.64739H6.47658V21.9998H2.32994V8.64739Z"
      fill={fillColor}
    />
  </svg>
)

export default IconLinkedin

IconLinkedin.propsTypes = {
  fillColor: PropTypes.string,
}

IconLinkedin.defaultProps = {
  fillColor: "#6080E9",
}
