import React from "react"

const IconMail = () => {
  return (
    <svg
      width="32"
      height="22"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_412_47)">
        <path
          d="M30.857 0.043457H1.14282L15.9999 11.7757L31.025 0.0763265C30.97 0.0609143 30.9139 0.049937 30.857 0.043457Z"
          fill="url(#paint0_linear_412_47)"
        />
        <path
          d="M16.7211 14.0415C16.3 14.372 15.6942 14.372 15.2731 14.0415L0 1.97827V20.8608C0 21.4659 0.51167 21.9564 1.14288 21.9564H30.8571C31.4883 21.9564 32 21.4659 32 20.8608V2.14044L16.7211 14.0415Z"
          fill="url(#paint1_linear_412_47)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i_412_47"
          x="0"
          y="0.043457"
          width="37"
          height="26.913"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="5" dy="5" />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.634056 0 0 0 0 0.627778 0 0 0 0 0.941667 0 0 0 0.58 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_412_47"
          />
        </filter>
        <linearGradient
          id="paint0_linear_412_47"
          x1="31.9999"
          y1="21.9565"
          x2="-2.10696"
          y2="4.0584"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17BEEF" />
          <stop offset="0.453125" stopColor="#807AEE" />
          <stop offset="1" stopColor="#F860FE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_412_47"
          x1="32"
          y1="21.9564"
          x2="-2.1069"
          y2="4.05835"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#17BEEF" />
          <stop offset="0.453125" stopColor="#807AEE" />
          <stop offset="1" stopColor="#F860FE" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconMail
