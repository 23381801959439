import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  color: #04062c;
  font-weight: bold;
  font-size: 16px;
  line-height: 8px;
`

const Username = styled.span`
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 1;
  color: #04062c;
  opacity: 0.7;
`
const Creator = ({ avatar, creator, username }) => {
  return (
    <Wrapper>
      {avatar}
      <StyledContent>
        {creator}
        <Username>{username}</Username>
      </StyledContent>
    </Wrapper>
  )
}

export default Creator

Creator.propTypes = {
  creator: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
}
