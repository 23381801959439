import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../../theme"

const StyledTextArea = styled.textarea`
  background: rgba(255, 255, 255, 0.9);
  font-family: var(--family);
  padding: 10px;
  opacity: 0.75;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
    -1px -1px 2px rgba(205, 219, 236, 0.5),
    inset -5px 5px 10px rgba(168, 181, 214, 0.2),
    inset 5px 5px 13px rgba(220, 231, 253, 0.7);
  backdrop-filter: blur(40px);
  border-radius: 4px;
  font-size: 16px;
  padding-left: 15px;
  line-height: 12px;
  letter-spacing: 0.055em;
  border: none;
  height: 120px;
  width: 100%;
  max-width: 100%;

  @media (min-width: ${breakpoints.medium}px) {
    font-size: 12px;
  }

  &:focus {
    color: rgba(4, 6, 44, 1);
    opacity: 1;
    outline: none;
  }
`

const TextArea = ({ field, form, ...props }) => {
  return (
    <StyledTextArea
      {...field}
      {...props}
      data-cy={field?.name || props?.name}
    />
  )
}

export default TextArea
