import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.label`
  display: flex;
  align-items: flex-start;
`
const StyledInput = styled.input`
  position: relative;
  width: 1.5em;
  flex: 0 0 1.5em;
  height: 1.5em;
  color: $black;
  border: 1px solid gray;
  border-radius: 4px;
  appearance: none;
  outline: 0;
  cursor: pointer;
  transition: background 175ms cubic-bezier(0.1, 0.1, 0.25, 1);

  &::before {
    position: absolute;
    content: "";
    display: block;
    top: 4px;
    left: 7px;
    width: 5px;
    height: 9px;
    border-style: solid;
    border-color: $white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    opacity: 0;
    box-sizing: border-box;
  }
  &:checked {
    color: #ffffff;
    border-color: #04062c;
    background: #04062c;
    &::before {
      opacity: 1;
    }
    ~ label::before {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
`
const StyledLabel = styled.span`
  position: relative;
  cursor: pointer;
  margin-top: 5px;
  padding: 0 0.25em 0;
  user-select: none;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;

  &::before {
    position: absolute;
    content: "";
    color: #898ab2;
    clip-path: polygon(0 0, 0 0, 0% 100%, 0 100%);
    transition: clip-path 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
`

const Checkbox = ({ field, label, ...props }) => {
  return (
    <Wrapper>
      <StyledInput type="checkbox" {...field} {...props} />
      <StyledLabel>{label}</StyledLabel>
    </Wrapper>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
}

export default Checkbox
