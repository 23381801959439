import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import { Footer as GrommetFooter, Box, Heading } from "grommet"
import { useLocalization } from "gatsby-theme-i18n"
import { Container } from "@bw/bits"
import { NewsLetter, Social } from "@bw/partials"
import styled from "styled-components"
import { mediaQuery as MQ } from "../../../theme.js"
import { useTranslation } from "react-i18next"

const StyledFooterFoot = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  padding: 32px 0;
  gap: 20px;

  ${MQ("375px")`
    grid-template-columns: 1fr 1fr;
  `}
`
const StyledLink = styled(Link)`
  color: #04062c;
  text-decoration: none;
  text-align: end;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;

  :hover {
    color: #6080e9;
  }
`

const StyledA = styled.a`
  color: #04062c;
  text-decoration: none;
  text-align: end;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;

  :hover {
    color: #6080e9;
  }
`

const StyledFooterLink = styled(Link)`
  color: #04062c;
  text-decoration: none;
  text-align: end;

  :hover {
    text-decoration: underline;
  }
`

const StyledFooterWrap = styled.section`
  position: relative;
  width: 100%;
  height: auto;
`
const StyledFooterContent = styled.section`
  position: relative;
  z-index: 5;
  width: 100%;
  margin: 50px 0 0;
`
const StyledFooterNav = styled.section`
  display: grid;
  align-items: flex-start;
  grid-template-columns: 1fr 1fr;
  grd-gap: 16px;

  ${MQ("small")`
    grid-template-columns: minmax(140px, auto) minmax(140px, auto) auto;
  `};

  ${MQ("medium")`
    grid-template-columns: auto auto auto;
    grid-gap: 20px;
  `};

  ${MQ("769px")`
      margin: 150px 0 0;
  `};

  ${MQ("large")`
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
  `};
`
const StyledFootHead = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  height: auto;

  // Border
  ::before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
    background: linear-gradient(
      270deg,
      #22b8ef 0%,
      #8a79f0 51.56%,
      #ed63fd 100%,
      #ed63fd 100%
    );
  }
`

const StyledFooterGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${props =>
    props.gap === "small" ? "6px" : props.gap === "medium" ? "12px" : "24px"};

  ${MQ("768px")`
      grid-template-columns: 300px 1fr;
    `};
`
const StyledFootHeadBlur = styled.div`
  width: 100%;
  height: 150px;
  background: #04062c;
  ::after {
    content: "";
    position: relative;
    z-index: -1;
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    height: 130px;
    opacity: 0.65;
    filter: blur(40px);
    border-radius: 60%;
    transform: translate(0, 50px);
    background: linear-gradient(
      266.15deg,
      ${props => props.$blurColors[0]} 41.74%,
      ${props => props.$blurColors[1]} 49.94%,
      ${props => props.$blurColors[2]} 57.17%
    );
  }
`
const Footer = ({ blurColors }) => {
  const { t } = useTranslation()
  const { locale } = useLocalization()

  const data = useStaticQuery(graphql`
    query FooterQuery {
      site {
        siteMetadata {
          title
        }
      }
      allGlobals(filter: { private_policy_page: { url: { ne: null } } }) {
        nodes {
          locale
          terms_and_conditions_page {
            url
          }
          private_policy_page {
            url
          }
        }
      }
    }
  `)

  const global = data.allGlobals.nodes.find(n => n.locale === locale)
  const { private_policy_page, terms_and_conditions_page } = global

  return (
    <GrommetFooter background="#fff">
      <StyledFooterWrap>
        <StyledFootHead>
          <StyledFootHeadBlur $blurColors={["#39abf0", "#8879ed", "#d569fa"]} />
        </StyledFootHead>
        <StyledFooterContent>
          <Container>
            <StyledFooterGrid>
              <Box width={{ max: "300px" }}>
                <NewsLetter
                  title={t("newsletter.title")}
                  infoFooter={t("newsletter.text")}
                  buttonLabel={t("newsletter.button")}
                />
              </Box>
              <StyledFooterNav>
                <Box gap="12px">
                  <Heading
                    level="3"
                    margin={{ bottom: "8px", top: "none" }}
                    textAlign="end"
                    color="brand"
                  >
                    NFT4Artist
                  </Heading>
                  <StyledLink to={t("links:blog.path")}>
                    {t("links:blog.label")}
                  </StyledLink>
                  <StyledLink to={t("links:roadmap.path")}>
                    {t("links:roadmap.label")}
                  </StyledLink>
                  <StyledLink to={t("links:suggestions.path")}>
                    {t("links:suggestions.label")}
                  </StyledLink>
                  <StyledA
                    href={t("links:faq.path")}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("links:faq.label")}
                  </StyledA>
                </Box>
                <Box gap="12px">
                  <Heading
                    level="3"
                    margin={{ bottom: "8px", top: "none" }}
                    textAlign="end"
                    color="brand"
                  >
                    NFT's
                  </Heading>
                  <StyledLink to={t("links:nfts.path")}>
                    {t("links:nfts.label")}
                  </StyledLink>
                  <StyledLink to={t("links:collections.path")}>
                    {t("links:collections.label")}
                  </StyledLink>
                </Box>
                <Box gap="12px">
                  <Heading
                    level="3"
                    margin={{ bottom: "8px", top: "none" }}
                    textAlign="end"
                    color="brand"
                  >
                    {t("stayInTouch")}
                  </Heading>
                  <Social displayNames />
                </Box>
              </StyledFooterNav>
            </StyledFooterGrid>
            <StyledFooterFoot>
              <Box>
                &copy; {new Date().getFullYear()} &mdash;{" "}
                {data.site.siteMetadata.title}
              </Box>
              <Box direction="row" justify="end" gap="24px">
                <StyledFooterLink
                  to={private_policy_page.url.replace("/en/", "/")}
                >
                  {t("links:privacy.label")}
                </StyledFooterLink>
                <StyledFooterLink
                  to={terms_and_conditions_page.url.replace("/en/", "/")}
                >
                  {t("links:terms.label")}
                </StyledFooterLink>
              </Box>
            </StyledFooterFoot>
          </Container>
        </StyledFooterContent>
      </StyledFooterWrap>
    </GrommetFooter>
  )
}

export default Footer

Footer.propTypes = {
  blurColors: PropTypes.arrayOf(PropTypes.string),
}

Footer.defaultProps = {
  blurColors: ["#39ABF0", "#8879ED", "#D569FA"],
}
