import React from "react"
import * as yup from "yup"
import { useLocalization } from "gatsby-theme-i18n"
import PropTypes from "prop-types"
import parse from "html-react-parser"
import styled from "styled-components"
import { Mail } from "@bw/icons"
import { Button, Checkbox } from "@bw/bits"
import { Input, FormField } from "@bw/forms"
import Theme from "../../../theme"
import { useTranslation } from "react-i18next"
import { Formik, Form, Field } from "formik"
import addToMailchimp from "../../../../plugins/gatsby-plugin-mailchimp"
import { useStaticQuery, graphql } from "gatsby"
import { toast } from "react-toastify"

const NewsLetterWrapper = styled.div`
  position: relative;
  background: #fff;
  box-shadow: 0px 0px 35px rgba(86, 112, 163, 0.1);
  border-radius: 19px;
  padding: 36px 25px 25px;
`

const Title = styled.div`
  font-weight: 700;
  font-size: 18px;
  line-height: 1.48;
  margin-bottom: 20px;
  padding-right: 35px;
`

const StyledIcon = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
  width: 32px;
  height: 22px;
  box-shadow: 5px 5px 10px rgba(162, 160, 240, 0.58);
`

const FormFooter = styled.div`
  margin: 20px 0 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 13.55px;
  color: ${Theme.global.colors.brand};
`
const userSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().required().email(),
  accept: yup.bool().oneOf([true], "Field must be checked"),
})

const NewsLetter = ({ title, infoFooter, buttonLabel }) => {
  const { t } = useTranslation()
  const { locale } = useLocalization()

  const data = useStaticQuery(graphql`
    query newsletterQuery {
      allGlobals(filter: { private_policy_page: { url: { ne: null } } }) {
        nodes {
          locale
          private_policy_page {
            url
          }
        }
      }
    }
  `)

  const global = data.allGlobals.nodes.find(n => n.locale === locale)
  const url = global.private_policy_page.url

  return (
    <NewsLetterWrapper>
      <StyledIcon>
        <Mail />
      </StyledIcon>
      <Title>{title}</Title>
      <Formik
        validationSchema={userSchema}
        initialValues={{ name: "", email: "", accept: null }}
        onSubmit={(values, { resetForm, setSubmitting }) => {
          addToMailchimp(values.email, {
            FNAME: values.name,
            "gdpr[63952]": values.accept ? "Y" : "N",
          })
            .then(data => {
              if (data.result === "success") {
                toast.success(<>{parse(data.msg)}</>)
                resetForm()
              } else {
                toast.error(<>{parse(data.msg)}</>)
              }
            })
            .catch(e => {
              console.log(e)
            })
            .finally(() => {
              setSubmitting(false)
            })
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <FormField name="name">
              <Field
                name="name"
                type="text"
                placeholder={t("newsletter.name")}
                value={values.name}
                component={Input}
              />
            </FormField>
            <FormField name="email">
              <Field
                name="email"
                type="email"
                placeholder={t("newsletter.email")}
                value={values.email}
                component={Input}
              />
            </FormField>
            <FormField name="accept">
              <Field
                name="accept"
                label={parse(
                  t("newsletter.acceptPrivacyNotice", {
                    url: url.replace("/en/", "/"),
                  })
                )}
                component={Checkbox}
              />
            </FormField>
            <Button
              submit
              primary
              label={buttonLabel}
              disabled={isSubmitting}
            />
          </Form>
        )}
      </Formik>
      <FormFooter>{infoFooter}</FormFooter>
    </NewsLetterWrapper>
  )
}

export default NewsLetter

NewsLetter.propTypes = {
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  infoFooter: PropTypes.string,
  buttonLabel: PropTypes.string,
}
