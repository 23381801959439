import React from "react"
import PropTypes from "prop-types"

const Arrow = ({ fillColor, className }) => (
  <svg
    width="4"
    height="8"
    viewBox="0 0 4 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4 3.99994L0 7.99994L-3.49691e-07 -6.10352e-05L4 3.99994Z"
      fill={fillColor}
    />
  </svg>
)

export default Arrow

Arrow.propsTypes = {
  fillColor: PropTypes.string,
  className: PropTypes.string,
}

Arrow.defaultProps = {
  fillColor: "#6080E9",
}
