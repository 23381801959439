import React from "react"

const PlusIcon = () => (
  <svg
    width="11"
    height="10"
    viewBox="0 0 11 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5.62607 0V9.57526" stroke="white" strokeWidth="2" />
    <path
      d="M10.6262 4.78763L0.626243 4.78763"
      stroke="white"
      strokeWidth="2"
    />
  </svg>
)

export default PlusIcon
