import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Image, Spacer } from "@bw/bits"
import { Like } from "@bw/icons"
import styled, { css } from "styled-components"
import { breakpoints } from "../../../theme"

const Card = styled(Link)`
  position: relative;
  width: 100%;
  height: fit-content;
  background: transparent;
  padding: 0 24px 24px;
  max-width: 240px;

  &:before {
    content: "";
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(228, 232, 244, 0.85) 0%,
      rgba(255, 255, 255, 0.85) 100%
    );
    box-shadow: -15px 15px 30px rgba(218, 225, 243, 0.2),
      15px -15px 30px rgba(218, 224, 240, 0.2),
      -15px -15px 30px rgba(255, 255, 255, 0.9),
      15px 15px 38px rgba(232, 234, 244, 0.9),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(219, 224, 241, 0.5);
    border-radius: 21px;
    backdrop-filter: blur(30px);
  }
`

const smallCardVariant = css`
  width: 100px;
  height: 100px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.small}px) {
    width: 140px;
  }
`

const CardImage = styled.figure`
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  ${props => (props.variant ? smallCardVariant : "")}
`

const Content = styled.div`
  position: relative;
  z-index: 5;
  color: #04062c;
  text-align: center;
  color: #000000;
  margin: ${props => (props.variant ? "14px 0 0;" : "24px 0 0;")};
`

const Title = styled.h4`
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  margin: 5px 0;
`

const CardInfos = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 1.1;
  margin-top: 20px;
  color: #6080e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  div {
    color: #aebef3;
  }
`

const CollectionTeaser = ({ to, image, name, likes, state, variant }) => {
  const source = {}

  if (typeof image === "string") {
    source.image = <Image image={image} type={variant ? "Small" : "NFT"} />
  } else {
    source.image = image
  }

  return (
    <Card to={to} state={state}>
      <CardImage variant={variant}>{source.image}</CardImage>
      <Content variant={variant}>
        <Title>{name}</Title>
        <CardInfos>
          {typeof likes !== "undefined" && (
            <Spacer direction="row" align="center">
              <Like />
              <div>{likes}</div>
            </Spacer>
          )}
        </CardInfos>
      </Content>
    </Card>
  )
}

export default CollectionTeaser

CollectionTeaser.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  to: PropTypes.string,
  likes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  state: PropTypes.object,
  variant: PropTypes.bool,
}
