import React from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Button } from "@bw/bits"

const StyledInput = styled.input`
  font-weight: 500;
  font-size: 10px;
  line-height: 142.5%;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: dashed 1.5px #6080e9;
  border-radius: 8px;
  letter-spacing: 0.055em;
  color: #6080e9;
  width: 100%;
  margin: 10px 0px;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`

const FileInput = props => {
  const inputRef = React.useRef(null)
  const { t } = useTranslation()

  return (
    <>
      <StyledInput {...props} data-cy={props.name} ref={inputRef} />
      {props.onClear &&
        inputRef?.current?.value &&
        inputRef.current.value.length > 0 && (
          <Button
            onClick={() => {
              inputRef.current.value = ""
              props.onClear()
            }}
            small
            label={t("reset")}
          />
        )}
    </>
  )
}

FileInput.propTypes = {
  onClear: PropTypes.func,
}

export default FileInput
