import React from "react"

const MenuLines = () => (
  <svg
    width="17"
    height="8"
    viewBox="0 0 17 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line y1="1" x2="17" y2="1" stroke="black" strokeWidth="2" />
    <line y1="7" x2="17" y2="7" stroke="black" strokeWidth="2" />
  </svg>
)

export default MenuLines
