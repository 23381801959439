import axios from "axios"
import { toast } from "react-toastify"
const { PublicKey } = require("@solana/web3.js")
const { Connection, programs } = require("@metaplex/js")

/**
 * getAccessToken is used to log the user in.
 */
export const getAccessToken = (email, password, dispatch) => {
  var data = new FormData()
  data.append("grant_type", "password")
  data.append("client_id", process.env.GATSBY_PASSPORT_CLIENT_ID)
  data.append("client_secret", process.env.GATSBY_PASSPORT_CLIENT_SECRET)
  data.append("username", email)
  data.append("password", password)

  return axios({
    url: "/oauth/token",
    method: "POST",
    data,
    headers: {
      "Content-type": "multipart/form-data",
    },
  }).then(response => {
    dispatch({ type: "SET_TOKEN", token: response.data.access_token })

    return response
  })
}

/**
 * getMe retrieves the current user data
 */
export const getMe = (dispatch, disconnect) => {
  return axios({
    url: "/api/profile/me",
    method: "GET",
  })
    .then(response => {
      dispatch({ type: "UPDATE_PROFILE_DATA", ...response.data.data })

      return response
    })
    .catch(err => {
      const message = err?.response?.data?.message
      if (message === "Invalid address header.") {
        dispatch({ type: "LOGOUT" })
        disconnect()
        toast.error(
          "The wallet address does not match with your NFT4Artists account."
        )
      }
    })
}

/**
 * Follow an User
 */
export const followUser = address => {
  return axios({
    method: "post",
    url: `/api/profile/${address}/follow`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * Unfollow an User
 */
export const unFollowUser = address => {
  return axios({
    method: "delete",
    url: `/api/profile/${address}/follow`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * getProfileByAddress retrieves a user information
 */
export const getProfileByAddress = address => {
  // throws an error if address is invalid
  new PublicKey(address)

  return axios({
    method: "GET",
    url: `/api/profile/${address}`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * getProfileNFTs Retrieves an account's NFTs
 */
export const getProfileNFTs = async address => {
  // throws an error if address is invalid
  new PublicKey(address)

  const connection = new Connection(process.env.GATSBY_SOLANA_NETWORK)
  console.info("getProfileNFTs is starting")

  const response = await programs.metadata.Metadata.findDataByOwner(
    connection,
    address
  )

  console.info(`getProfileNFTs found ${response.length} NFTs`)
  if (response.length === 0) {
    return []
  }

  let mint, json
  let nfts = []

  for (let i = 0; i < response.length; i++) {
    if (response[i].data.uri === null || response[i].data.uri.length === 0) {
      // there are some tokens that look like NFTs but are not.
      continue
    }

    try {
      mint = await fetch(response[i].data.uri)
      json = await mint.json()

      nfts.push({
        ...response[i],
        mintData: json,
      })
    } catch {
      console.log(`Failed to fetch ${response[i].data.uri}`)
    }
  }

  return nfts
}

/**
 * getArtistOfTheDay retrieves the artist of the day
 */
export const getArtistOfTheDay = () => {
  return axios({
    method: "get",
    url: `/api/artist-of-the-day`,
  }).then(response => {
    return response.data.data
  })
}

/**
 * editProfile updates data
 */
export const editProfile = (userdata, dispatch) => {
  const dataToSend = new FormData()
  dataToSend.append("_method", "PUT")
  dataToSend.append("name", userdata.name)
  dataToSend.append("email", userdata.email)
  dataToSend.append("bio", userdata.bio)
  dataToSend.append("website", userdata.website)

  if (typeof userdata.avatar !== "undefined" && userdata.avatar.size > 0) {
    dataToSend.append("avatar", userdata.avatar)
  }
  return axios
    .post(`/api/profile`, dataToSend, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then(response => {
      if (response.status !== 200) {
        throw new Error("Invalid server response")
      }

      dispatch({ type: "UPDATE_PROFILE_DATA", ...response.data.data })
    })
}

/**
 * createProfile creates a new profile
 */
export const createProfile = userdata => {
  const dataToSend = new FormData()
  dataToSend.append("name", userdata.name)
  dataToSend.append("email", userdata.email)
  dataToSend.append("password", userdata.password)
  dataToSend.append("repeatpassword", userdata.repeatpassword)
  dataToSend.append("bio", userdata.bio)
  dataToSend.append("website", userdata.website)
  dataToSend.append(
    "privacy_notice_accepted",
    userdata.privacy_notice_accepted ? 1 : 0
  )
  dataToSend.append(
    "server_storage_accepted",
    userdata.server_storage_accepted ? 1 : 0
  )

  if (userdata.avatar !== null && userdata.avatar.size > 0) {
    dataToSend.append("avatar", userdata.avatar)
  }

  return axios.post(`/api/profile`, dataToSend, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
}

export const getBalance = address => {
  const connection = new Connection(process.env.GATSBY_SOLANA_NETWORK)
  return connection.getBalance(new PublicKey(address))
}

export const forgotPassword = (email, lang) => {
  return axios.post(`/forgot-password`, { email, lang })
}

export const resetPassword = ({
  email,
  lang,
  password,
  password_confirmation,
  token,
}) => {
  return axios.post(`/reset-password`, {
    email,
    lang,
    password,
    password_confirmation,
    token,
  })
}
