import React from "react"
import PropTypes from "prop-types"

const IconSearch = ({ fillColor }) => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.991 11.5735C9.82823 12.7737 8.19946 13.5197 6.39644 13.5197C2.86378 13.5197 0 10.6559 0 7.12325C0 3.59059 2.86378 0.726807 6.39644 0.726807C9.9291 0.726807 12.7929 3.59059 12.7929 7.12325C12.7929 8.13211 12.5593 9.08643 12.1433 9.93507L15.012 12.2021C15.4453 12.5445 15.519 13.1734 15.1766 13.6067C14.8342 14.04 14.2053 14.1137 13.772 13.7712L10.991 11.5735ZM10.7929 7.12325C10.7929 9.55134 8.82453 11.5197 6.39644 11.5197C3.96835 11.5197 2 9.55134 2 7.12325C2 4.69516 3.96835 2.72681 6.39644 2.72681C8.82453 2.72681 10.7929 4.69516 10.7929 7.12325Z"
      fill={fillColor}
    />
  </svg>
)

export default IconSearch

IconSearch.propsTypes = {
  fillColor: PropTypes.string,
}

IconSearch.defaultProps = {
  fillColor: "#04062C",
}
