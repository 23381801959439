import React from "react"

const CarouselArrowIcon = () => (
  <svg
    width="7"
    height="12"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.33333 5.99998L0.5 11.8333L0.499999 0.166646L6.33333 5.99998Z"
      fill="#04062C"
    />
  </svg>
)

export default CarouselArrowIcon
