import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import CarouselArrowIcon from "./arrow-carousel"
import styled from "styled-components"

const StyledBox = styled(Box)`
  background: linear-gradient(135deg, #ffffff 0%, #eaeaea 100%);
  box-shadow: -5px 5px 10px rgba(199, 198, 231, 0.2),
    5px -5px 10px rgba(190, 193, 224, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.9),
    5px 5px 13px rgba(214, 216, 228, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(192, 187, 221, 0.5);
  border-radius: 6px;
  height: 40px;
  width: 40px;
  justify-content: center;
`

const RowReverse = styled(Box)`
  align-items: center;
  transform: ${props => (props.direction === "left" ? "scaleX(-1)" : "none")};
`

const CarouselArrow = ({ direction }) => {
  return (
    <StyledBox direction={direction}>
      <RowReverse direction={direction}>
        <CarouselArrowIcon />
      </RowReverse>
    </StyledBox>
  )
}

export default CarouselArrow

CarouselArrow.propTypes = {
  direction: PropTypes.oneOf(["left", "right"]),
}

CarouselArrow.defaultProps = {
  direction: "right",
}
