import React from "react"
import { Avatar } from "@bw/bits"
import { useWallet } from "@solana/wallet-adapter-react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import { useLocation } from "@reach/router"
import { useUser } from "@contexts/user"
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui"
import "./styles.css"

const UserConnect = () => {
  const { localizedPath, locale, defaultLang } = useLocalization()
  const { wallet, publicKey } = useWallet()
  const [user, dispatch] = useUser()
  const location = useLocation()
  const base58 = React.useMemo(() => publicKey?.toBase58(), [publicKey])

  const data = useStaticQuery(graphql`
    query UserConnectQuery {
      allGlobals(
        filter: {
          terms_and_conditions_page: { url: { ne: null } }
          private_policy_page: { url: { ne: null } }
        }
      ) {
        nodes {
          locale
          terms_and_conditions_page {
            url
          }
          private_policy_page {
            url
          }
        }
      }
    }
  `)

  const global = data.allGlobals.nodes.find(n => n.locale === locale)
  const { private_policy_page, terms_and_conditions_page } = global

  React.useEffect(() => {
    if (wallet && base58) {
      dispatch({ type: "SET_PUBLIC_KEY", publicKey: base58 })
    } else {
      dispatch({ type: "UNSET_PUBLIC_KEY" })
    }
  }, [wallet, base58, dispatch])

  React.useEffect(() => {
    if (
      wallet &&
      base58 &&
      user.token === null &&
      !location.pathname.includes(
        private_policy_page.url.replace("/en/", "/")
      ) &&
      !location.pathname.includes(
        terms_and_conditions_page.url.replace("/en/", "/")
      ) &&
      !location.pathname.endsWith("/user/register/") &&
      !location.pathname.endsWith("/user/login/") &&
      !location.pathname.endsWith("/user/forgot-password/") &&
      !location.pathname.endsWith("/user/reset-password/")
    ) {
      navigate(
        localizedPath({
          defaultLang,
          locale,
          path: `/user/login/`,
        })
      )
    }
  }, [
    wallet,
    base58,
    user,
    location,
    private_policy_page.url,
    terms_and_conditions_page.url,
    defaultLang,
    locale,
    localizedPath,
  ])

  /**
   * if window is not defined, we dont render at all
   */
  if (typeof window === "undefined") {
    return null
  }

  return wallet && base58 ? (
    <Link to={`/user/${base58}`}>
      <Avatar image={user.data.avatar_url || null} alt="Profile picture" />
    </Link>
  ) : (
    <WalletModalProvider>
      <WalletMultiButton />
    </WalletModalProvider>
  )
}

export default UserConnect
