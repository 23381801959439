import React from "react"
import PropTypes from "prop-types"

const IconDiscord = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8886 12.6425C14.4703 12.6425 14.944 12.1425 14.944 11.5258C14.944 10.9133 14.4728 10.4133 13.8886 10.4133V10.4158C13.3093 10.4158 12.8348 10.9141 12.8331 11.5308C12.8331 12.1425 13.3069 12.6425 13.8886 12.6425Z"
      fill={fillColor}
    />
    <path
      d="M11.1655 11.5258C11.1655 12.1425 10.6918 12.6425 10.11 12.6425C9.52831 12.6425 9.05458 12.1425 9.05458 11.5308C9.05458 10.9141 9.52831 10.4158 10.11 10.4158L10.1133 10.4133C10.695 10.4133 11.1655 10.9133 11.1655 11.5258Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.11091 19.6633H16.7774L16.2202 17.8258L17.553 18.9908L18.7745 20.1058L21 22V4.0625C20.9444 2.9475 20.0018 2 18.8335 2L5.115 2.0025C3.94745 2.0025 3 2.95167 3 4.06667V17.6C3 18.7758 3.94582 19.6633 5.11091 19.6633ZM13.7411 6.73583L13.7141 6.74583L13.7239 6.73583H13.7411ZM7.49755 7.79333C8.99727 6.68167 10.3874 6.735 10.3874 6.735L10.4995 6.8475C8.66427 7.29333 7.83218 8.12833 7.83218 8.12833C7.83218 8.12833 8.05473 8.0175 8.44336 7.84833C10.9151 6.85833 13.6143 6.93 16.113 8.18333C16.113 8.18333 15.2793 7.4025 13.557 6.9025L13.7092 6.75C13.9473 6.75083 15.2073 6.79583 16.5556 7.8C16.5556 7.8 18.0644 10.425 18.0644 13.65C18.0553 13.6389 18.019 13.677 17.9522 13.7474C17.6488 14.0665 16.7164 15.0474 14.8481 15.0883C14.8481 15.0883 14.4619 14.6433 14.187 14.255C15.5206 13.865 16.0197 13.085 16.0197 13.085C15.7027 13.2884 15.4058 13.4319 15.1432 13.5589C15.0432 13.6072 14.9481 13.6532 14.8587 13.6992C14.372 13.9173 13.8844 14.0274 13.3976 14.1372L13.3631 14.145C11.1595 14.5092 9.85849 13.9642 8.67652 13.4691C8.59295 13.4341 8.50966 13.3992 8.427 13.365L7.99909 13.1433C7.99909 13.1433 8.49736 13.9233 9.77782 14.3133C9.44155 14.7042 9.10855 15.1483 9.10855 15.1483C6.82909 15.0933 5.99782 13.6433 5.99782 13.6433C5.99782 10.4133 7.49755 7.79333 7.49755 7.79333Z"
      fill={fillColor}
    />
  </svg>
)

export default IconDiscord

IconDiscord.propsTypes = {
  fillColor: PropTypes.string,
}

IconDiscord.defaultProps = {
  fillColor: "#6080E9",
}
