import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: ${props => (props.$maxWidth ? props.$maxWidth : "100%")};
  padding-top: 18%;
  margin: ${props => (props.$margin ? props.$margin : "0 auto")};
  border-radius: 15px;
  overflow: hidden;
`

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
  background-image: url(${props => props.$image});
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`
const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff;
`

const Image = ({ image, size, margin, maxWidth }) => {
  if (image.hasOwnProperty("childImageSharp")) {
    image = <StyledGatsbyImage image={getImage(image)} alt="" />
  } else {
    image = <BackgroundImage $image={image} />
  }

  return (
    <Wrapper $size={size} $margin={margin} $maxWidth={maxWidth}>
      {image}
    </Wrapper>
  )
}

export default Image

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  margin: PropTypes.string,
  maxWidth: PropTypes.string,
}
