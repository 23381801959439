import React from "react"
import PropTypes from "prop-types"
import { Box, Heading, Paragraph } from "grommet"
import { KeyValue, Image } from "@bw/bits"
import { Like } from "@bw/icons"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  display: block;
  width: 100%;
  height: auto;
  text-decoration: none;
  color: #04062c;
`
const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 160px 1fr;
  height: 100%;
  grid-gap: 15px;

  @media (min-width: 420px) {
    grid-gap: 25px;
    grid-template-columns: 216px 1fr;
  }
`

const StyledHeading = styled(Heading)`
  font-weight: 800;
  font-size: 24px;
  line-height: 1.2;
  color: #6080e9;
  margin: 0;

  @media (min-width: 420px) {
    font-size: 28px;
  }
`

const StyledBox = styled(Box)`
  justify-content: space-between;
  max-width: 213px;
`
const StyledParagraph = styled(Paragraph)`
  display: none;

  @media (min-width: 420px) {
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 19px;
    opacity: 0.7;
  }
`

const Subtitle = styled(Paragraph)`
  font-weight: 800;
  font-size: 16px;
  line-height: 22px;
  color: #000000;

  @media (min-width: 420px) {
    font-size: 18px;
  }
`
const WrapLikes = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  gap: 10px;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #6080e9;
  margin-top: 16px;

  svg {
    width: 16px;
    height: auto;
  }

  span {
    opacity: 0.8;
  }
`

const Name = styled(Heading)`
  font-weight: 900;
  font-size: 18px;
  line-height: 1.2;
  margin: 10px 0;
  color: #000000;

  @media (min-width: 420px) {
    font-size: 20px;
  }
`

function truncate(input) {
  if (input && input.length > 120) {
    return input.substring(0, 120).trim() + "[...]"
  }
  return input
}

const HighlightTeaser = ({
  date,
  to,
  image,
  avatar,
  title,
  subtitle,
  name,
  author,
  likeCount,
  excerpt,
  imageType,
  footer,
}) => {
  const highlight = (
    <StyledWrapper>
      <Image type={imageType} image={image} tag={date} avatar={avatar} />
      <StyledBox>
        <Box>
          <StyledHeading level="3">{title}</StyledHeading>
          <Subtitle>{subtitle}</Subtitle>
          <Name level="5">{name}</Name>
          <StyledParagraph>{truncate(excerpt)}</StyledParagraph>
          {likeCount > 0 && (
            <WrapLikes>
              <Like fillColor="#6080E9" />
              <span>{likeCount}</span>
            </WrapLikes>
          )}
          <StyledParagraph>{footer}</StyledParagraph>
        </Box>
        {author && (
          <KeyValue indicator="par" variable={author} direction="row" />
        )}
      </StyledBox>
    </StyledWrapper>
  )

  if (to) {
    return <StyledLink to={to}>{highlight}</StyledLink>
  } else {
    return highlight
  }
}

export default HighlightTeaser

HighlightTeaser.propTypes = {
  avatar: PropTypes.element,
  date: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.string,
  excerpt: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  author: PropTypes.string,
  likeCount: PropTypes.number,
  footer: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  to: PropTypes.string,
  imageType: PropTypes.string,
}

HighlightTeaser.defaultProps = {
  imageType: "Highlight",
}
