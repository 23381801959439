import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { breakpoints } from "../../../theme"

const Grid = ({ columns, responsive, children }) => {
  const Wrapper = Columns[columns]
  return <Wrapper $responsive={responsive}>{children}</Wrapper>
}

Grid.propTypes = {
  responsive: PropTypes.bool,
  columns: PropTypes.oneOf([2, 3, 4]),
}

Grid.defaultProps = {
  responsive: true,
  columns: 2,
}

export default Grid

const base = css`
  display: grid;
  grid-gap: 25px;

  @media (min-width: ${breakpoints.small}px) {
    grid-gap: 65px;
  }
`

const Columns = {
  2: styled.div`
    ${base}

    ${props =>
      props.$responsive
        ? css`
            @media (min-width: ${breakpoints.small}px) {
              grid-template-columns: 1fr 1fr;
            }
          `
        : css`
            grid-template-columns: 1fr 1fr;
          `}
  `,
  3: styled.div`
    ${base}

    ${props =>
      props.$responsive
        ? css`
            @media (min-width: ${breakpoints.small}px) {
              grid-template-columns: 1fr 1fr 1fr;
            }
          `
        : css`
            grid-template-columns: 1fr 1fr 1fr;
          `}
  `,
  4: styled.div`
    ${base}

    ${props =>
      props.$responsive
        ? css`
            @media (min-width: ${breakpoints.small}px) {
              grid-template-columns: 1fr 1fr;
            }

            @media (min-width: ${breakpoints.large}px) {
              grid-template-columns: 1fr 1fr 1fr 1fr;
            }
          `
        : css`
            grid-template-columns: 1fr 1fr 1fr 1fr;
          `}
  `,
}
