import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import styled from "styled-components"

const StyledText = styled(Box)`
  color: #04062c;
  font-weight: bold;
  font-size: ${props => (props.size === "small" ? "10px" : "14px")};
  line-height: 1.2;
`

const Indicator = styled.div`
  font-size: ${props => (props.$direction === "row" ? "1em" : "1.2em")};
  margin: ${props => (props.$direction === "row" ? "0 .5em 0 0" : "0 0 .8em")};
`

const Variable = styled.div`
  opacity: 50%;
`

const KeyValue = ({ variable, indicator, size, direction }) => {
  return (
    <StyledText size={size} direction={direction}>
      <Indicator $direction={direction}>{indicator}</Indicator>
      <Variable>{variable}</Variable>
    </StyledText>
  )
}

export default KeyValue

KeyValue.propTypes = {
  indicator: PropTypes.string.isRequired,
  variable: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  size: PropTypes.oneOf(["small", "large"]),
  direction: PropTypes.oneOf(["row", "column"]),
}

KeyValue.defaultProps = {
  size: "small",
  direction: "row",
}
