import React from "react"
import PropTypes from "prop-types"

const IconTwitter = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.534 6.98769C22.51 6.27692 23.331 5.38974 24 4.36821V4.36718C23.107 4.76821 22.157 5.03487 21.165 5.1641C22.185 4.53949 22.964 3.55795 23.33 2.37538C22.379 2.95692 21.329 3.36718 20.21 3.59692C19.307 2.61026 18.02 2 16.616 2C13.892 2 11.699 4.26769 11.699 7.04718C11.699 7.44718 11.732 7.8318 11.813 8.19795C7.723 7.99282 4.103 5.98256 1.671 2.91897C1.247 3.67487 0.997 4.53949 0.997 5.46974C0.997 7.21744 1.874 8.76615 3.183 9.66359C2.392 9.64821 1.617 9.41231 0.96 9.04205V9.09744C0.96 11.5497 2.665 13.5867 4.902 14.0564C4.501 14.1692 4.065 14.2226 3.612 14.2226C3.297 14.2226 2.979 14.2041 2.681 14.1364C3.318 16.1344 5.128 17.6041 7.278 17.6523C5.604 18.9949 3.478 19.8041 1.177 19.8041C0.774 19.8041 0.387 19.7856 0 19.7354C2.18 21.1764 4.762 22 7.548 22C16.231 22 21.89 14.5703 21.534 6.98769V6.98769Z"
      fill={fillColor}
    />
  </svg>
)

export default IconTwitter

IconTwitter.propsTypes = {
  fillColor: PropTypes.string,
}

IconTwitter.defaultProps = {
  fillColor: "#6080E9",
}
