import React from "react"
import PropTypes from "prop-types"

const IconTelegram = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12.3584 9.38246C11.3857 9.78701 9.44178 10.6243 6.52658 11.8945C6.05319 12.0827 5.80521 12.2669 5.78264 12.4469C5.74449 12.7513 6.12559 12.8711 6.64456 13.0343C6.71515 13.0565 6.7883 13.0795 6.86328 13.1039C7.37386 13.2698 8.06069 13.464 8.41774 13.4717C8.74162 13.4787 9.10311 13.3452 9.5022 13.0711C12.226 11.2325 13.632 10.3032 13.7203 10.2832C13.7825 10.269 13.8688 10.2512 13.9273 10.3032C13.9858 10.3552 13.98 10.4536 13.9738 10.48C13.9361 10.641 12.4401 12.0318 11.6659 12.7515C11.4246 12.9759 11.2534 13.135 11.2184 13.1714C11.14 13.2528 11.0601 13.3298 10.9833 13.4039C10.509 13.8611 10.1533 14.204 11.003 14.764C11.4114 15.0331 11.7381 15.2556 12.0641 15.4776C12.4201 15.7201 12.7752 15.9619 13.2347 16.2631C13.3517 16.3398 13.4635 16.4195 13.5724 16.4971C13.9867 16.7925 14.359 17.0579 14.8188 17.0156C15.086 16.991 15.362 16.7397 15.5022 15.9903C15.8335 14.2193 16.4847 10.3821 16.6352 8.80083C16.6484 8.66229 16.6318 8.48499 16.6185 8.40716C16.6051 8.32933 16.5773 8.21844 16.4761 8.13635C16.3563 8.03913 16.1714 8.01863 16.0886 8.02001C15.7125 8.02671 15.1355 8.22736 12.3584 9.38246Z"
      fill={fillColor}
    />
  </svg>
)

export default IconTelegram

IconTelegram.propsTypes = {
  fillColor: PropTypes.string,
}

IconTelegram.defaultProps = {
  fillColor: "#6080E9",
}
