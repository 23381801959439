import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { breakpoints } from "../../../theme"

const Spacer = ({ children, direction, gap, align }) => {
  const SpacerType = direction === "column" ? VerticalSpacer : HorizontalSpacer

  return (
    <SpacerType gap={gaps[gap]} align={align}>
      {children}
    </SpacerType>
  )
}

const gaps = {
  small: css`
    gap: 15px;
  `,

  medium: css`
    gap: 15px;

    @media (min-width: ${breakpoints.medium}px) {
      gap: 25px;
    }
  `,

  large: css`
    gap: 15px;

    @media (min-width: ${breakpoints.medium}px) {
      gap: 45px;
    }
  `,
}

const VerticalSpacer = styled.div`
  display: grid;
  ${props => props.gap};
`

const HorizontalSpacer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: ${props => props.align};
  ${props => props.gap};
`

export default Spacer

Spacer.propTypes = {
  direction: PropTypes.oneOf(["column", "row"]),
  gap: PropTypes.oneOf(["small", "medium", "large"]),
  align: PropTypes.oneOf(["flex-start", "center", "flex-end"]),
}

Spacer.defaultProps = {
  direction: "column",
  gap: "medium",
  fixed: false,
  align: "flex-start",
}
