import React from "react"

const Upload = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.23591 0.0343049C6.17315 0.057131 6.07603 0.107735 6.02009 0.146785C5.96416 0.185836 5.24283 0.895732 4.41716 1.7243C3.00061 3.1458 2.91289 3.23911 2.86198 3.37777C2.79607 3.55733 2.79144 3.79491 2.85048 3.96896C2.90883 4.14096 3.15473 4.38687 3.32673 4.44521C3.50134 4.50442 3.73849 4.49965 3.91793 4.43331C4.05303 4.38336 4.12986 4.3144 4.87056 3.5781L5.67625 2.7772L5.68306 6.01278L5.68989 9.24838L5.74719 9.36343C5.83743 9.54456 5.93991 9.65133 6.11327 9.74482C6.26006 9.82396 6.29243 9.83143 6.48969 9.83143C6.6868 9.83143 6.7194 9.82394 6.8655 9.74515C7.0431 9.64938 7.11961 9.57038 7.22107 9.37805L7.28949 9.24838L7.29633 6.01278L7.30313 2.7772L8.10882 3.5781C8.85922 4.32405 8.92496 4.38288 9.06684 4.43554C9.26646 4.50963 9.54393 4.50161 9.71735 4.41675C9.86537 4.34431 10.0284 4.18087 10.1014 4.03163C10.1849 3.86098 10.1929 3.58196 10.1198 3.38315C10.0661 3.2371 9.99959 3.16662 8.51162 1.67931C7.25225 0.420522 6.93547 0.11685 6.83246 0.0696486C6.66567 -0.00680252 6.39346 -0.0229256 6.23591 0.0343049ZM0.611762 11.392C0.383474 11.4476 0.173342 11.6193 0.0657108 11.8382C-0.0200079 12.0126 -0.0222169 12.34 0.0611659 12.5119C0.148154 12.6911 0.251367 12.7992 0.425775 12.8937L0.586371 12.9808H6.48969H12.393L12.5227 12.9124C12.715 12.8109 12.794 12.7344 12.8898 12.5568C12.9686 12.4107 12.9761 12.3781 12.9761 12.181C12.9761 11.9837 12.9686 11.9514 12.8894 11.8046C12.796 11.6312 12.6892 11.5287 12.5081 11.4385L12.393 11.3812L6.54047 11.3773C3.32158 11.3752 0.653656 11.3818 0.611762 11.392Z"
      fill="#04062C"
    />
  </svg>
)

export default Upload
