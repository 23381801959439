import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import ArrowDropdown from "./arrow-dropdown"
import { Box, DropButton, List } from "grommet"

const buttonStyle = css`
  padding: 10px 20px;
  background: rgba(255, 255, 255, 0.5);
  border: none;
  border-radius: 7px;
  text-decoration: none;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  line-height: 14.4px;
  font-size: 12px;
  font-weight: 800;
  letter-spacing: 4.5%
  text-transform: uppercase;
  cursor: pointer;
  transition: filter 0.6s ease-in-out;
  width: ${props => (props.$fill ? "100%" : null)};
  min-width: ${props => (props.$icon ? "150px" : null)};

  &:hover icon {
    filter: saturate(1.5) brightness(1.1);
  }
`

const StyledDropButton = styled(DropButton)`
  ${buttonStyle};
  position: relative;
  white-space: nowrap;
  border-radius: 5px;

  &:before {
    content: "";
    display: ${props => (props.$active ? "block" : "none")};
    width: 5px;
    height: 5px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    background-color: var(--primary);
  }

  &:active,
  &:hover,
  &:focus {
    border: none;
  }
`

const StyledList = styled(List)`
display: flex;
flex-direction: column;
align-items: flex-start;
font-weight: bold;
  padding: 14px;

  ul {
    padding: 0;
  }
  li {
    padding 10px 0;
    font-size: 12px;
    border: none;
    cursor: pointer;
    width: 100%;
    justify-content: start;

    &:after {
      border-bottom: 0.5px solid rgba(4, 6, 44, 0.2);
      content: "";
      display: none;
      margin: 0 auto;
      width: 50%;
      padding-top: 20px;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.6);
      transition: background 0.1s ease-in-out;
    }
    &:active, &:hover, &:focus {
      border: none;
    }
    

    span {
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.055em;
      color: var(--brand);
    }
  }
`

const Gap = styled.div`
  width: 40px;
`
const IconWrapper = styled.div`
  margin-top: -1px;
  transform: rotate(90deg);
`

const StyledSubtitle = styled(Box)`
  white-space: nowrap;
  font-size: 9px;
  line-height: 10px;
  display: flex;
  align-items: center;
  letter-spacing: 0.055em;
  position: absolute;
  top: -6px;
`

const StyledIndicator = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${props => (props.$active ? "var(--primary)" : null)};
  border-radius: 50%;
`

const DropButtons = props => {
  const [categories, setCategories] = React.useState(props.categories || [])
  const items = props.stateless ? props.categories : categories

  let icon = null
  if (props.icon) {
    if (props.icon === "ArrowDropdown") {
      icon = <ArrowDropdown />
    }
    icon = (
      <>
        <Gap />
        <IconWrapper>{icon}</IconWrapper>
      </>
    )
  }
  const activeSelection = items.find(c => c.active)

  return (
    <StyledDropButton
      $active={items.filter(c => c.active).length > 0}
      $fill={props.fill}
      dropAlign={{ top: "bottom", right: "right" }}
      dropContent={
        <StyledList
          onClickItem={({ item, index }) => {
            let newItems = [...items]

            if (!props.multiple) {
              newItems = newItems.map(c => {
                c.active = false
                return c
              })
            }

            newItems[index] = { ...item, active: !item.active }

            setCategories(newItems)
            props.onChange(
              newItems.map(c => ({ ...c })),
              newItems[index]
            )
          }}
          primaryKey="indicator"
          secondaryKey="name"
          data={items.map((category, i) => ({
            ...category,
            index: i,
            indicator: <StyledIndicator $active={category.active} />,
          }))}
          defaultItemProps={{ align: "start" }}
        />
      }
    >
      <StyledSubtitle>{props.subtitle}</StyledSubtitle>
      {props.multiple ? props.label : activeSelection.name}
      {icon}
    </StyledDropButton>
  )
}

DropButtons.propTypes = {
  subtitle: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  icon: PropTypes.oneOf(["ArrowDropdown", null]),
  multiple: PropTypes.bool,
  stateless: PropTypes.bool,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      name: PropTypes.string,
    })
  ),
}

DropButtons.defaultProps = {
  icon: "ArrowDropdown",
  multiple: true,
  stateless: false,
}

export default DropButtons
