import React from "react"
import PropTypes from "prop-types"
import { Box, Heading, Text } from "grommet"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import { Link } from "gatsby"
import { Avatar, Button } from "@bw/bits"
import defaultImage from "../../../images/icon.png"

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #04062c;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const StyledWrapper = styled(Box)`
  background: #ffffff;
  position: relative;
  box-shadow: 5px 5px 15px rgba(190, 199, 230, 0.5);
  border-radius: 15px;
  height: 213px;
  width: 171px;
  align-items: center;
`

const StyledText = styled(Text)`
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.055em;
  color: ${props =>
    props.color === "first"
      ? "#FFB800"
      : props.color === "second"
      ? "#BCBCBC"
      : props.color === "third"
      ? "#BB916A"
      : "#04062C"};
`

const StyledHeading = styled(Heading)`
  font-weight: 800;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  margin: 2px 0;
  align-items: center;
  text-align: center;
  letter-spacing: 0.055em;
  color: #04062c;
`

const StyledSold = styled(Text)`
  font-weight: 800;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.055em;
  color: #04062c;
  opacity: 0.5;
  margin-bottom: 10px;
`

const StyledTop = styled(Link)`
  width: 100%;
  height: 80px;
  position: relative;
  border-radius: 15px 15px 0px 0px;
  z-index: 2;
`

const Background = styled.div`
  filter: blur(3px);
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  height: 100%;
  width: auto;
`

const StyledBottom = styled(Box)`
  justify-content: end;
  align-items: center;
  padding: 25px 22px 18px 22px;
  width: 100%;
  height: 65%;
`
const AvatarWrapper = styled(Box)`
  position: absolute;
  z-index: 3;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
`

const ArtistTeaser = ({
  to,
  background,
  avatar,
  color,
  rank,
  pseudo,
  sold,
  button,
}) => {
  const { t } = useTranslation()

  if (!avatar) {
    avatar = <Avatar image={defaultImage} size="medium" dropshadow="without" />
  }

  if (!background) {
    background = defaultImage
  }

  return (
    <StyledWrapper>
      <StyledTop to={to}>
        <AvatarWrapper dropshadow="without">{avatar}</AvatarWrapper>
        <Background background={background} />
      </StyledTop>
      <StyledBottom>
        <StyledLink to={to}>
          <StyledText color={color}>{rank}</StyledText>
          <StyledHeading level="3">{pseudo}</StyledHeading>
          <StyledSold>{sold} SOL</StyledSold>
        </StyledLink>
        {button}
      </StyledBottom>
    </StyledWrapper>
  )
}

export default ArtistTeaser

ArtistTeaser.propTypes = {
  to: PropTypes.string,
  avatar: PropTypes.element,
  color: PropTypes.oneOf(["first", "second", "third", "other"]).isRequired,
  rank: PropTypes.number.isRequired,
  pseudo: PropTypes.string.isRequired,
  sold: PropTypes.number.isRequired,
  button: PropTypes.element,
}

ArtistTeaser.defaultProps = {
  color: "other",
}
