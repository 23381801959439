import React from "react"
import PropTypes from "prop-types"
import { Facebook, FacebookMessenger, Twitter, WhatsApp } from "@bw/icons"
import styled from "styled-components"
import { mediaQuery as MQ } from "../../../theme"

const StyledShareLink = styled.a`
  background: transparent;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);

  :hover:after {
    opacity: 1;
    visibility: visible;
  }

  :after {
    content: attr(data-name);
    position: absolute;
    top: -4px;
    left: 50%;
    padding: 6px 10px;
    white-space: nowrap;
    box-shadow: 0px 10px 25px rgba(83, 120, 255, 0.4);
    border-radius: 6px;
    background: #04062c;
    opacity: 0;
    z-index: 99999;
    transform: translate(-50%, -100%);
    visibility: hidden;
    transition: opacity 0.4s ease-in-out;
  }

  svg {
    transition: opacity 0.4s ease-in-out;

    :hover {
      opacity: 0.8;
    }
  }
`
const StyledGrid = styled.div`
  display: ${props => (props.row ? "flex" : "grid")};
  grid-template-columns: repeat(auto-fill, minmax(25px, 1fr));
  width: auto;
  height: auto;
  ${props => (props.gap ? "gap:" + props.gap : "gap:15px")};
`
const StyledShareLinkMessenger = styled(StyledShareLink)`
  transition: all 0.4s ease-in-out;

  ${MQ("768px")`
    display: none;
    visibility: hidden;
  `}
`

const Share = ({ gap, quote, pageURL, row }) => {
  pageURL = encodeURIComponent(pageURL)
  quote = encodeURIComponent(quote)
  const links = {
    twitter: {
      name: "Twitter",
      url: `https://twitter.com/intent/tweet/?url=${pageURL}&text=${quote}`,
    },
    facebook: {
      name: "Facebook",
      url: `https://www.facebook.com/sharer/sharer.php?u=${pageURL}&text=${quote}`,
    },
    whatsApp: {
      name: "WhatsApp",
      url: `https://api.whatsapp.com/send?text=${pageURL}`,
    },
    facebookMessenger: {
      name: "Facebook Messenger",
      url: `fb-messenger://share/?link=${pageURL}`,
    },
  }
  return (
    <StyledGrid gap={gap} row={row}>
      <StyledShareLink
        href={links.facebook.url}
        data-name={links.facebook.name}
        target="_blank"
        rel="noreferrer"
      >
        <Facebook />
      </StyledShareLink>
      <StyledShareLink
        href={links.twitter.url}
        data-name={links.twitter.name}
        target="_blank"
        rel="noreferrer"
      >
        <Twitter />
      </StyledShareLink>
      <StyledShareLink
        href={links.whatsApp.url}
        data-name={links.whatsApp.name}
        target="_blank"
        rel="noreferrer"
      >
        <WhatsApp />
      </StyledShareLink>
      <StyledShareLinkMessenger
        href={links.facebookMessenger.url}
        data-name={links.facebookMessenger.name}
        target="_blank"
        rel="noreferrer"
      >
        <FacebookMessenger />
      </StyledShareLinkMessenger>
    </StyledGrid>
  )
}

export default Share

Share.propTypes = {
  gap: PropTypes.string,
  row: PropTypes.bool,
  quote: PropTypes.string.isRequired,
  pageURL: PropTypes.string.isRequired,
}
