import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../../theme"

const StyledInput = styled.input`
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.75;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
    -1px -1px 2px rgba(205, 219, 236, 0.5),
    inset -5px 5px 10px rgba(168, 181, 214, 0.2),
    inset 5px 5px 13px rgba(220, 231, 253, 0.7);
  backdrop-filter: blur(40px);
  border-radius: 4px;
  font-size: 16px;
  padding-left: 15px;
  line-height: 12px;
  letter-spacing: 0.055em;
  border: none;
  height: 35px;
  width: 100%;
  margin: ${props => props.margin};
  -webkit-appearance: none;

  @media (min-width: ${breakpoints.medium}px) {
    font-size: 12px;
  }

  &:focus {
    color: rgba(4, 6, 44, 1);
    opacity: 1;
    outline: none;
  }

  &[readonly] {
    cursor: not-allowed;
    background-color: #ddd;
  }
`

const InputIcon = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 10px;
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.75;
  padding-right: 5px;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
    -1px -1px 2px rgba(205, 219, 236, 0.5),
    inset -5px 5px 10px rgba(168, 181, 214, 0.2),
    inset 5px 5px 13px rgba(220, 231, 253, 0.7);
  backdrop-filter: blur(40px);
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.055em;
  border: none;
  height: 35px;
  width: 100%;
  margin: ${props => props.margin};

  &:focus-within {
    color: rgba(4, 6, 44, 1);
    opacity: 1;
    outline: none;
  }

  span {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 50%;

    &:after {
      content: "";
      display: block;
      height: 80%;
      width: 2px;
      top: 0;
      right: 0;
      position: absolute;
      transform: translateY(10%);
      background: rgba(4, 6, 44, 0.3);
    }
  }

  input {
    background: none;
    border: none;
    height: 100%;

    &:focus-visible,
    &:focus {
      outline: none;
    }
  }
`

const Input = ({ field, icon, ...props }) => {
  if (icon) {
    return (
      <InputIcon>
        <span>{icon}</span>
        <input {...field} {...props} data-cy={field?.name || props?.name} />
      </InputIcon>
    )
  } else {
    return (
      <StyledInput {...field} {...props} data-cy={field?.name || props?.name} />
    )
  }
}

export default Input
