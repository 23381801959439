import React from "react"
import PropTypes from "prop-types"

const IconShare = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5078 14.499C16.4814 14.499 15.5693 14.9978 15.001 15.7656L10.108 13.2651C10.1901 12.9862 10.2344 12.6913 10.2344 12.3862C10.2344 11.973 10.1534 11.5784 10.0067 11.2171L15.1348 8.13605C15.707 8.80763 16.5585 9.23437 17.5078 9.23437C19.2266 9.23437 20.625 7.836 20.625 6.11719C20.625 4.39837 19.2266 3 17.5078 3C15.789 3 14.3906 4.39837 14.3906 6.11719C14.3906 6.51446 14.4655 6.89451 14.6016 7.24422L9.46062 10.333C8.88886 9.68124 8.0503 9.26901 7.11719 9.26901C5.39837 9.26901 4 10.6674 4 12.3862C4 14.105 5.39837 15.5034 7.11719 15.5034C8.16048 15.5034 9.08559 14.9881 9.6517 14.1988L14.5304 16.692C14.4396 16.9842 14.3906 17.2945 14.3906 17.6161C14.3906 19.335 15.789 20.7333 17.5078 20.7333C19.2266 20.7333 20.625 19.335 20.625 17.6161C20.625 15.8973 19.2266 14.499 17.5078 14.499ZM17.5078 4.03906C18.6537 4.03906 19.5859 4.97131 19.5859 6.11719C19.5859 7.26307 18.6537 8.19531 17.5078 8.19531C16.3619 8.19531 15.4297 7.26307 15.4297 6.11719C15.4297 4.97131 16.3619 4.03906 17.5078 4.03906ZM7.11719 14.4643C5.97131 14.4643 5.03906 13.5321 5.03906 12.3862C5.03906 11.2403 5.97131 10.3081 7.11719 10.3081C8.26307 10.3081 9.19531 11.2403 9.19531 12.3862C9.19531 13.5321 8.26307 14.4643 7.11719 14.4643ZM17.5078 19.6943C16.3619 19.6943 15.4297 18.762 15.4297 17.6161C15.4297 16.4703 16.3619 15.538 17.5078 15.538C18.6537 15.538 19.5859 16.4703 19.5859 17.6161C19.5859 18.762 18.6537 19.6943 17.5078 19.6943Z"
      fill={fillColor}
      stroke={fillColor}
      strokeWidth="0.7"
    />
  </svg>
)

export default IconShare

IconShare.propsTypes = {
  fillColor: PropTypes.string,
}

IconShare.defaultProps = {
  fillColor: "#04062C",
}
