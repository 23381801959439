import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import styled from "styled-components"
import { Container } from "@bw/bits"
import Logo from "../../../images/logo-NFT4artists"
import LogoMobile from "../../../images/logo-NFT4artists-mobile"
import { motion } from "framer-motion"
import { ResponsiveContext } from "grommet"
import { breakpoints } from "../../../theme"

const Layer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  background-color: #f3f4fa;
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`

const LogoWrapper = styled(Link)`
  height: 55px;
  padding: 10px 25px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: auto;
  }
`

const Close = styled.div`
  cursor: pointer;
`

const StyledLogoMobile = styled(LogoMobile)`
  display: none;
  (max-width: ${breakpoints.small}px) {
    display: block;
  }
`

const StyledLogo = styled(Logo)`
  display: block;
  (max-width: ${breakpoints.small}px) {
    display: none;
  }
`

const variants = {
  open: { opacity: 1, y: 0, display: "block" },
  closed: { opacity: 0, y: "-40px", transitionEnd: { display: "none" } },
}

const Logos = ({ mode }) => {
  const LogoResponsive = mode === "desktop" ? StyledLogo : StyledLogoMobile
  return <LogoResponsive />
}

const LayerMenu = ({ children, show, setShow }) => {
  const size = React.useContext(ResponsiveContext)
  return (
    <Layer
      animate={show ? "open" : "closed"}
      variants={variants}
      initial={false}
    >
      <Container>
        <Header>
          <LogoWrapper to="/">
            <Logos mode={size === "small" ? "mobile" : "desktop"} />
          </LogoWrapper>
          <Close
            role="button"
            tabIndex="0"
            onKeyDown={() => {
              setShow(false)
            }}
            onClick={() => {
              setShow(false)
            }}
          >
            <svg aria-label="Close" viewBox="0 0 24 24" height="24" width="24">
              <path
                fill="none"
                stroke="#000"
                strokeWidth="2"
                d="m3 3 18 18M3 21 21 3"
              />
            </svg>
          </Close>
        </Header>
      </Container>
      {children}
    </Layer>
  )
}

export default LayerMenu
