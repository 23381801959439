import React from "react"
import PropTypes from "prop-types"

const IconFacebookMessenger = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 11.2592C2 14.1725 3.45333 16.7717 5.72583 18.4692V22L9.13083 20.1317C10.0392 20.3825 11.0017 20.5192 11.9992 20.5192C17.5217 20.5192 21.9992 16.3742 21.9992 11.26C22 6.14583 17.5225 2 12 2C6.4775 2 2 6.145 2 11.2592H2ZM10.945 8.66583L13.5533 11.3817L18.4592 8.66583L12.9925 14.4675L10.4467 11.7525L5.4775 14.4683L10.945 8.66583Z"
      fill={fillColor}
    />
  </svg>
)

export default IconFacebookMessenger

IconFacebookMessenger.propsTypes = {
  fillColor: PropTypes.string,
}

IconFacebookMessenger.defaultProps = {
  fillColor: "#6080E9",
}
