import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const StyledContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 ${props => (props.pad === "small" ? "6px" : "15px")};
  position: relative;
  max-width: ${props =>
    props.size === "small"
      ? "556px"
      : props.size === "medium"
      ? "996px"
      : "1100px"};
`

const Container = ({ size, children, pad, direction, justify }) => {
  return (
    <StyledContainer size={size} pad={pad}>
      {children}
    </StyledContainer>
  )
}

export default Container

Container.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  pad: PropTypes.oneOf(["small", "medium"]),
}

Container.defaultProps = {
  size: "large",
}
