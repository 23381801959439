import React from "react"
import PropTypes from "prop-types"
import { Container, Title } from "@bw/bits"
import styled from "styled-components"
import { breakpoints } from "../../../theme"

const StyledSection = styled.div`
  background-color: ${props => props.$backgroundColor};
  padding: ${props => (props.$backgroundColor ? "75px 0" : null)};
  margin: ${props => (props.$margin ? props.$margin : "60px 0")};

  @media (min-width: ${breakpoints.small}px) {
    margin: ${props => (props.$margin ? props.$margin : "75px 0")};
    padding: ${props => (props.$backgroundColor ? "75px 0" : null)};
  }
`
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${props =>
    props.$justify === "center" ? "center" : `flex-${props.$justify}`};
  margin-top: 45px;
`

const Grid = styled.div`
  @media (min-width: ${breakpoints.medium}px) {
    display: grid;
    grid-template-columns: 2fr 3fr;
  }

  @media (min-width: ${breakpoints.medium}px) {
    grid-template-columns: 1fr 2fr;
  }
`

const Section = ({
  suptitle,
  title,
  subtitle,
  fullWidth,
  containerSize,
  children,
  button,
  buttonPosition,
  titleOnSide,
  backgroundColor,
  margin,
  padding,
}) => {
  if (titleOnSide) {
    return (
      <StyledSection
        $backgroundColor={backgroundColor}
        $padding={padding}
        $margin={margin}
      >
        <Container size={containerSize}>
          <Grid>
            {(suptitle || title) && (
              <Title suptitle={suptitle} title={title} subtitle={subtitle} />
            )}
            <div>
              {children}

              {button && (
                <Container size={containerSize}>
                  <ButtonWrapper $justify={buttonPosition}>
                    {button}
                  </ButtonWrapper>
                </Container>
              )}
            </div>
          </Grid>
        </Container>
      </StyledSection>
    )
  }

  const content = fullWidth ? (
    children
  ) : (
    <Container size={containerSize}>{children}</Container>
  )

  return (
    <StyledSection
      $backgroundColor={backgroundColor}
      $padding={padding}
      $margin={margin}
    >
      {(suptitle || title) && (
        <Container size={containerSize}>
          <Title suptitle={suptitle} title={title} subtitle={subtitle} />
        </Container>
      )}
      {content}
      {button && (
        <Container size={containerSize}>
          <ButtonWrapper $justify={buttonPosition}>{button}</ButtonWrapper>
        </Container>
      )}
    </StyledSection>
  )
}

Section.propTypes = {
  suptitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fullWidth: PropTypes.bool,
  titleOnSide: PropTypes.bool,
  button: PropTypes.element,
  backgroundColor: PropTypes.string,
  buttonPosition: PropTypes.oneOf(["start", "center", "end"]),
  containerSize: PropTypes.oneOf(["small", "medium", "large"]),
  padding: PropTypes.string,
  margin: PropTypes.string,
}

Section.defaultProps = {
  buttonPosition: "center",
  containerSize: "medium",
}

export default Section
