import React from "react"
import PropTypes from "prop-types"

const IconFacebook = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.175 5.32083H17.0008V2.14083C16.6858 2.0975 15.6025 2 14.3408 2C11.7083 2 9.905 3.65583 9.905 6.69917V9.5H7V13.055H9.905V22H13.4667V13.0558H16.2542L16.6967 9.50083H13.4658V7.05167C13.4667 6.02417 13.7433 5.32083 15.175 5.32083V5.32083Z"
      fill={fillColor}
    />
  </svg>
)

export default IconFacebook

IconFacebook.propsTypes = {
  fillColor: PropTypes.string,
}

IconFacebook.defaultProps = {
  fillColor: "#6080E9",
}
