import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Public, Private, Upload } from "@bw/icons"
import { Error } from "@bw/forms"

const Wrapper = styled.div`
  margin-bottom: 20px;
`

const Label = styled.label`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.025em;
  color: #04062c;
  margin-bottom: 0.8em;
`

const IconWrapper = styled.div`
  margin-top: -1px;
`

const Help = styled.div`
  margin-top: 2px;
  font-size: 12px;
  line-height: 14px;
`

const FormField = props => {
  let icon = null
  if (props.icon) {
    switch (props.icon) {
      case "Upload":
        icon = <Upload />
        break
      case "Public":
        icon = <Public />
        break
      case "Private":
      default:
        icon = <Private />
        break
    }
    icon = <IconWrapper>{icon}</IconWrapper>
  }

  return (
    <Wrapper>
      {props.title && (
        <Label htmlFor={props.name}>
          {props.title}
          {icon}
        </Label>
      )}
      {props.children}
      {props.help && <Help>{props.help}</Help>}
      {props.name && <Error name={props.name} />}
    </Wrapper>
  )
}

FormField.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  help: PropTypes.string,
  icon: PropTypes.oneOf(["Private", "Upload", "Public"]),
}

export default FormField
