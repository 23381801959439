import React from "react"
import PropTypes from "prop-types"
import { KeyValue } from "@bw/bits"
import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  background: linear-gradient(135deg, #eceefc 0%, #ffffff 100%);
  box-shadow: -5px 5px 10px rgba(231, 232, 250, 0.2),
    5px -5px 10px rgba(225, 221, 243, 0.2),
    -5px -5px 10px rgba(255, 255, 255, 0.9),
    5px 5px 13px rgba(238, 238, 252, 0.9),
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(222, 222, 248, 0.5);
  border-radius: 11px;
  max-width: 329px;
  height: 57px;
  padding: 12px 23px;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

const Price = styled.span`
  font-weight: 800;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  letter-spacing: 0.025em;
  color: #6080e9;
`

const Historic = ({ avatar, price, buyer, date }) => {
  return (
    <Wrapper>
      <Flex>
        {avatar}
        <KeyValue direction="column" indicator={buyer} variable={date} />
      </Flex>
      <Price>{price}SOL</Price>
    </Wrapper>
  )
}

export default Historic

Historic.propTypes = {
  avatar: PropTypes.element,
  price: PropTypes.string,
  buyer: PropTypes.string,
  date: PropTypes.string,
}
