import React from "react"
import PropTypes from "prop-types"

const IconWhatsApp = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5817 13.9851L16.5892 13.9226C16.6159 13.9346 16.6417 13.9461 16.6665 13.9571C16.873 14.049 17.0091 14.1096 17.0642 14.2101C17.1267 14.3226 17.1267 14.8101 16.9142 15.3976C16.7067 15.9851 15.7125 16.5101 15.2342 16.5859C14.805 16.6476 14.2625 16.6743 13.6675 16.4851C11.4642 15.7284 10.2592 15.1834 7.99749 12.2234C6.84666 10.6018 6.50749 9.07676 7.85249 7.59842C8.16166 7.27676 8.51249 7.27009 8.99249 7.31176C9.00764 7.31176 9.02328 7.31116 9.03936 7.31055C9.18704 7.30493 9.37214 7.29789 9.55249 7.73676C9.64384 7.95088 9.79079 8.30665 9.93229 8.64921C10.1135 9.08788 10.2857 9.5049 10.3208 9.57509C10.3825 9.69926 10.425 9.83676 10.3417 10.0118C10.1456 10.3827 9.94148 10.608 9.78916 10.776C9.58015 11.0066 9.46875 11.1295 9.60999 11.3734C9.75666 11.6234 10.2533 12.4359 10.9933 13.0984C11.7884 13.8098 12.4802 14.1097 12.8312 14.2619C12.8996 14.2915 12.9551 14.3155 12.9958 14.3359C13.2433 14.4484 13.3883 14.4359 13.5342 14.2734C13.6783 14.1084 14.1567 13.5509 14.3208 13.3051C14.5452 12.9686 14.7291 13.0604 16.3085 13.8488C16.3953 13.8921 16.4862 13.9375 16.5817 13.9851Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.08417 11.9112C2.08833 3.17286 12.6925 -1.31964 19.1 4.87453L19.0875 4.90703C20.9667 6.77869 22 9.26953 22 11.9162C21.9967 19.457 13.8667 24.182 7.27917 20.6237L2 22.0004L3.41333 16.8654C2.54167 15.3612 2.08417 13.6579 2.08417 11.9112ZM7.825 18.9754C13.3475 22.222 20.33 18.2495 20.335 11.8887C20.335 9.68869 19.4733 7.61286 17.9108 6.06369C16.3475 4.50119 14.2717 3.63869 12.06 3.63869C5.55 3.63869 1.60167 10.7887 5.03833 16.2595L5.2375 16.572L4.4 19.6095L7.525 18.797L7.825 18.9754Z"
      fill={fillColor}
    />
  </svg>
)

export default IconWhatsApp

IconWhatsApp.propsTypes = {
  fillColor: PropTypes.string,
}

IconWhatsApp.defaultProps = {
  fillColor: "#6080E9",
}
