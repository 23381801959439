import React, { useState } from "react"
import PropTypes from "prop-types"
import { motion } from "framer-motion"
import styled from "styled-components"

const Wrap = styled.div`
  position: relative;
  width: auto;
  height: 285px;

  @media (min-width: 420px) {
    height: 385px;
  }
`

const Slide = styled(motion.div)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: fit-content;
  opacity: 0;
`

const NavigationDots = styled.div`
  position: absolute;
  left: 175px;
  bottom: 0;
  right: 0;
  z-index: 19;
  display: flex;
  justify-content: flex-start;

  @media (min-width: 420px) {
    left: 240px;
  }
`

const Dot = styled(motion.button)`
  flex: 0 1 30px;
  height: 6px;
  background-color: ${props => (props.isSelected ? "#04062C" : "#e6e7ed")};
  cursor: pointer;
  border: 0;
  user-select: ${props => (props.isSelected ? "none" : null)};
  transition: background-color 0.5s;

  &:hover {
    background-color: #04062c;
  }

  &:not(:last-of-type) {
    margin-right: 8px;
  }
`

const variants = {
  open: { opacity: 1, display: "block", transition: { duration: 1 } },
  closed: { opacity: 0, transitionEnd: { display: "none" } },
}

const Fader = ({ slides }) => {
  const [currentPage, setCurrentPage] = useState(0)

  function setPage(newPage) {
    setCurrentPage(newPage)
  }

  let interval

  React.useEffect(() => {
    interval = setInterval(() => {
      setCurrentPage(currentPage => {
        let next = currentPage + 1
        if (next >= slides.length) {
          next = 0
        }
        return next
      })
    }, 4000)
    return () => {
      clearInterval(interval)
    }
  }, [slides])

  return (
    <Wrap>
      {slides.map((slide, i) => {
        return (
          <Slide
            key={i}
            variants={variants}
            animate={i === currentPage ? "open" : "closed"}
          >
            {slide}
          </Slide>
        )
      })}
      <NavigationDots>
        {slides.map((slide, i) => (
          <Dot
            key={i}
            onClick={() => {
              setPage(i)
              clearInterval(interval)
            }}
            isSelected={i === currentPage}
          />
        ))}
      </NavigationDots>
    </Wrap>
  )
}

export default Fader

Fader.propTypes = {
  slides: PropTypes.array,
}
