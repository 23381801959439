import React from "react"
import PropTypes from "prop-types"
import { Box, Heading, Paragraph } from "grommet"
import styled from "styled-components"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #04062c;
`
const StyledWrapper = styled(Box)`
  background: linear-gradient(135deg, #e8e9f2 0%, #ffffff 100%);
  box-shadow: -15px 15px 30px rgba(216, 220, 240, 0.2),
    15px -15px 30px rgba(204, 202, 235, 0.2),
    -15px -15px 30px rgba(255, 255, 255, 0.9), 15px 15px 38px #e8ebf5,
    inset 1px 1px 2px rgba(255, 255, 255, 0.3),
    inset -1px -1px 2px rgba(210, 209, 238, 0.5);
  border-radius: 21px;
  padding: 26px 28px;
  position: relative;
  margin-top: 24px;
  width: 100%;
  height: 333px;
`
const StyledHeading = styled(Heading)`
  font-weight: 900;
  margin: 0 0 5px 0;
  font-size: 16px;
  line-height: 19px;
  transform: translateY(-20px);
`

const StyledImage = styled(Box)`
  transform: translateY(-50px);
`

const StyledText = styled.div`
  transform: translateY(-20px);
`

const StyledParagraph = styled(Paragraph)`
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  opacity: 0.7;
  transform: translateY(-20px);
`

const NewsTeaser = ({ to, image, title, subtitle, excerpt, state }) => {
  return (
    <StyledLink to={to} state={state}>
      <StyledWrapper>
        <StyledImage>{image}</StyledImage>
        <StyledHeading level="3">{title}</StyledHeading>
        <StyledText>{subtitle}</StyledText>
        <StyledParagraph>{excerpt}</StyledParagraph>
      </StyledWrapper>
    </StyledLink>
  )
}

export default NewsTeaser

NewsTeaser.propTypes = {
  avatar: PropTypes.element,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  excerpt: PropTypes.string,
  to: PropTypes.string,
  state: PropTypes.object,
}
