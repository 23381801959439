import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import styled, { css } from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  max-width: ${props =>
    props.$type === "NFT"
      ? "212px"
      : props.$type === "ArtistHighlight"
      ? "216px"
      : props.$type === "Highlight"
      ? "216px"
      : props.$type === "BlogListing"
      ? "196px"
      : props.$type === "BlogDetail"
      ? "556px"
      : props.$type === "Timeline"
      ? "435px"
      : "355px"};
  width: 100%;
  margin: ${props =>
    props.$type === "ArtistHighlight" ||
    props.$type === "NFTDetail" ||
    props.$type === "Highlight" ||
    props.$type === "Timeline"
      ? "0"
      : "0 auto"};
`

const Ratio = styled.div`
  width: 100%;
  position: relative;
  height: ${props => (props.$height ? "100%" : "")};
  padding-top: ${props =>
    props.$type === "NFT"
      ? "96%"
      : props.$type === "NFTDetail"
      ? "0"
      : props.$type === "Small"
      ? "75%"
      : props.$type === "ArtistHighlight"
      ? "144%"
      : props.$type === "Highlight"
      ? "178.75%"
      : props.$type === "BlogListing"
      ? "70%"
      : props.$type === "BlogDetail"
      ? "67%"
      : props.$type === "Timeline"
      ? "60%"
      : "100%"};

  &:after {
    background: linear-gradient(
      239.97deg,
      ${props => props.$colors[0]} 24.3%,
      ${props => props.$colors[1]} 62.81%,
      ${props => props.$colors[2]} 96.78%
    );
    z-index: 1;
    filter: blur(16px);
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    display: block;
    opacity: 0.65;
    left: 20px;
    right: 20px;
    height: 80px;
    content: "";
    transform: translateY(27px);
  }
`

const Tag = styled.div`
  background: linear-gradient(
    264.34deg,
    rgba(255, 255, 255, 0.35) 4.39%,
    rgba(218, 220, 251, 0.35) 46.5%,
    rgba(162, 166, 244, 0.35) 100%
  );
  position: absolute;
  padding: 5px 14px;
  top: 10px;
  left: 10px;
  box-shadow: 0px 10px 25px rgba(83, 120, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 6px;
  font-weight: 800;
  font-size: 11px;
  line-height: 11px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.025em;
  text-transform: uppercase;
  color: #ffffff;
  z-index: 2;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  position: absolute !important;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  border-radius: 17px;
  background-color: #fff;
  filter: drop-shadow(0px 0px 20px rgba(96, 128, 233, 0.21));
  overflow: hidden;
  z-index: 2;
`

const Avatar = styled(Box)`
  position: absolute;
  z-index: 7;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
`

const StyledImage = styled.img`
  position: relative;
  ${props =>
    props.$type !== "NFTDetail" &&
    css`
      position: absolute !important;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      height: 100%;
      object-fit: cover;
    `}
  width: 100%;
  border-radius: 17px;
  background-color: #fff;
  filter: drop-shadow(0px 0px 20px rgba(96, 128, 233, 0.21));
  overflow: hidden;
  z-index: 2;
`

const Image = ({ image, avatar, tag, type, colors, height, alt }) => {
  if (!image) {
    return null
  }
  if (image.hasOwnProperty("childImageSharp")) {
    image = <StyledGatsbyImage image={getImage(image)} alt={alt} />
  } else {
    image = <StyledImage src={image} alt={alt} $type={type} />
  }

  return (
    <Wrapper $type={type}>
      <Ratio $colors={colors} $type={type} $height={height}>
        {image}
        {tag && <Tag>{tag}</Tag>}
        {avatar && <Avatar>{avatar}</Avatar>}
      </Ratio>
    </Wrapper>
  )
}

export default Image

Image.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  alt: PropTypes.string,
  avatar: PropTypes.element,
  tag: PropTypes.string,
  type: PropTypes.oneOf([
    "NFT",
    "Highlight",
    "BlogListing",
    "BlogDetail",
    "NFTDetail",
    "Timeline",
  ]),
  height: PropTypes.bool,
  colors: PropTypes.arrayOf(PropTypes.string),
}

Image.defaultProps = {
  colors: ["#39ABF0", "#8879ED", "#D569FA"],
}
