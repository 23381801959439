import React from "react"
import { navigate } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n"
import styled, { css } from "styled-components"
import LayerMenu from "./LayerMenu"
import Logo from "../../../images/logo-NFT4artists"
import LogoMobile from "../../../images/logo-NFT4artists-mobile"
import MenuLines from "./menuLines"
import { Social, UserConnect } from "@bw/partials"
import { useTranslation } from "react-i18next"
import { useWallet } from "@solana/wallet-adapter-react"
import { useUser } from "@contexts/user"
import { Container, Section, Button, DropButtons } from "@bw/bits"
import { Box, ResponsiveContext } from "grommet"
import { breakpoints } from "../../../theme"

const Header = styled.div`
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 20;
  height: 80px;
  color: #04062c;
  transition: background-color 0.3s, color 0.3s;
`
const linkStyle = css`
  text-decoration: none;
  font-weight: 800;
  font-size: 24px;
  line-height: 1.2;
  letter-spacing: 0.055em;

  color: #04062c;
  margin: 2vh 0px;
  transition: color 0.4s ease-in-out;

  @media (min-width: 800px) {
    font-size: 28px;
  }

  :hover {
    color: #6080e9;
  }
`
const MenuLink = styled(Link)`
  ${linkStyle}
`

const MenuA = styled.a`
  ${linkStyle}
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr 35px;
  grid-gap: 20px;

  @media (min-width: 375px) {
    grid-template-columns: auto 1fr 72px;
  }

  @media (min-width: 800px) {
    grid-gap: 70px;
    grid-template-columns: auto 1fr 72px;
  }
`

const LogoWrapper = styled(Link)`
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    height: auto;
  }
`

const MainMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: end;
`

const activeStyle = {
  fontWeight: 800,
}

const Burger = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-family: Lato;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.055em;
  color: #04062c;
  cursor: pointer;

  &:selected,
  &:hover {
    opacity: 0.8;
  }

  span {
    display: none;

    @media (min-width: 375px) {
      display: block;
    }
  }
`

const StyledFooter = styled.footer`
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  background: linear-gradient(264.34deg, #04062c 4.39%, #101461 100%);
  margin-top: auto;
  position: absolute;
  bottom: 0;
  a {
    color: #fff;
    text-decoration: none;
    transition: all 0.4s ease-in-out;

    &:hover {
      opacity: 0.8;
    }
  }
`
const StyledLogoMobile = styled(LogoMobile)`
  display: none;
  (max-width: ${breakpoints.small}px) {
    display: block;
  }
`

const StyledLogo = styled(Logo)`
  display: block;
  (max-width: ${breakpoints.small}px) {
    display: none;
  }
`

const Links = ({ mode, menu }) => {
  return (
    <Box $direction={mode === "desktop" ? "row" : "column"}>
      {menu.map((item, i) => {
        if (item.to.startsWith("http")) {
          return (
            <MenuA
              key={i}
              href={item.to}
              activeStyle={activeStyle}
              target="_blank"
              rel="noreferrer"
            >
              {item.label}
            </MenuA>
          )
        } else {
          return (
            <MenuLink key={i} to={item.to} activeStyle={activeStyle}>
              {item.label}
            </MenuLink>
          )
        }
      })}
    </Box>
  )
}

const Logos = ({ mode }) => {
  const LogoResponsive = mode === "desktop" ? StyledLogo : StyledLogoMobile
  return <LogoResponsive />
}
const HeaderLayout = ({ pageContext }) => {
  const { originalPath, translations } = pageContext
  const { localizedPath, locale, defaultLang, config } = useLocalization()
  const { t } = useTranslation()
  const { disconnect } = useWallet()
  const [user, dispatch] = useUser()
  const size = React.useContext(ResponsiveContext)
  const [show, setShow] = React.useState(false)
  const headerRef = React.useRef(null)

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allGlobals(filter: { private_policy_page: { url: { ne: null } } }) {
        nodes {
          locale
          terms_and_conditions_page {
            url
          }
          private_policy_page {
            url
          }
        }
      }
    }
  `)

  const global = data.allGlobals.nodes.find(n => n.locale === locale)
  const { private_policy_page, terms_and_conditions_page } = global

  const menu = [
    {
      to: t("links:nfts.path"),
      label: t("links:nfts.label"),
    },
    {
      to: t("links:collections.path"),
      label: t("links:collections.label"),
    },
    { to: t("links:blog.path"), label: t("links:blog.label") },
    { to: t("links:roadmap.path"), label: t("links:roadmap.label") },
    { to: t("links:faq.path"), label: t("links:faq.label") },
  ]

  if (user.loggedIn) {
    if (user.data.is_artist) {
      menu.push({
        to: t("links:createcollection.path"),
        label: t("links:createcollection.label"),
      })
      menu.push({
        to: t("links:createmint.path"),
        label: t("links:createmint.label"),
      })
    } else {
      menu.push({
        to: t("links:becomeCreator.path"),
        label: t("links:becomeCreator.label"),
      })
    }
  }

  const scrollBehaviour = () => {
    if (window.scrollY > 10) {
      headerRef.current.style.backgroundColor = "rgba(255,255,255,0.75)"
      headerRef.current.style.backdropFilter = "blur(30px)"
    } else {
      headerRef.current.style.backgroundColor = "transparent"
      headerRef.current.style.backdropFilter = "none"
    }
  }

  if (typeof window !== "undefined") {
    if (show) {
      document.querySelector("body").style.overflowY = "hidden"
    } else {
      document.querySelector("body").style.overflowY = "auto"
    }
  }

  React.useEffect(() => {
    scrollBehaviour()
    window.addEventListener("scroll", scrollBehaviour)

    return () => {
      window.removeEventListener("scroll", scrollBehaviour)
    }
  }, [])

  return (
    <Header ref={headerRef} data-cy="header" className="header">
      <Container>
        <Grid>
          <LogoWrapper to="/">
            <Logos
              mode={
                size === "small" || size === "xsmall" ? "mobile" : "desktop"
              }
            />
          </LogoWrapper>
          <MainMenu>
            <UserConnect />
          </MainMenu>
          <Burger
            data-cy="burger"
            role="button"
            tabIndex="0"
            onKeyDown={() => {
              setShow(true)
            }}
            onClick={() => {
              setShow(true)
            }}
          >
            <MenuLines />
            <span>Menu</span>
          </Burger>
        </Grid>
      </Container>
      {/*
       ____                            
      | __ ) _   _ _ __ __ _  ___ _ __ 
      |  _ \| | | | '__/ _` |/ _ \ '__|
      | |_) | |_| | | | (_| |  __/ |   
      |____/ \__,_|_|  \__, |\___|_|   
                       |___/           
      */}
      <LayerMenu show={show} setShow={setShow}>
        <Section containerSize="large">
          <Links mode={size === "small" ? "mobile" : "desktop"} menu={menu} />
          <Box pad={{ top: "50px" }} width="small" direction="column">
            {(user.token || user.publicKey) && (
              <Button
                data-cy="logout"
                onClick={() => {
                  dispatch({ type: "LOGOUT" })
                  disconnect()
                  setShow(false)
                }}
                label={t("user.signout")}
              />
            )}
          </Box>
          <Box pad={{ top: "50px" }} width="medium">
            <Social
              row
              gap={size === "small" ? "40px" : "70px"}
              fillColorTwitter="#6080E9"
              fillColorInsta="#5A97F0"
              fillColorDiscord="#827FF0"
            />
          </Box>
          <Box pad={{ top: "50px" }} width="medium">
            <DropButtons
              label={t("language")}
              icon="ArrowDropdown"
              multiple={false}
              onChange={(_, selection) => {
                const { locale } = selection

                const targetPath = translations
                  ? translations.find(t => t.locale === locale).slug
                  : localizedPath({
                      defaultLang,
                      locale,
                      path: originalPath || "/",
                    })

                navigate(targetPath)
              }}
              categories={[
                { active: locale === "en", name: "English", locale: "en" },
                { active: locale === "fr", name: "Français", locale: "fr" },
              ]}
            />
          </Box>
        </Section>
        <StyledFooter>
          <Container>
            <Box direction="row" align="start" gap="large">
              <Link to={private_policy_page.url.replace("/en/", "/")}>
                {t("links:privacy.label")}
              </Link>
              <Link to={terms_and_conditions_page.url.replace("/en/", "/")}>
                {t("links:terms.label")}
              </Link>
            </Box>
          </Container>
        </StyledFooter>
      </LayerMenu>
    </Header>
  )
}

export default HeaderLayout
