import React from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { Link } from "gatsby"
import PlusIcon from "./plus-icon"
import Arrowleft from "./arrow-left"

export const buttonStyle = css`
  color: #fff;
  padding: ${props => (props.$small ? "10px 20px" : "13px 22px")};
  font-size: ${props => (props.$small ? "9px" : "12px")};
  background: linear-gradient(264.34deg, #04062c 4.39%, #101461 100%);
  box-shadow: 0px 10px 25px rgba(83, 120, 255, 0.4);
  backdrop-filter: blur(20px);
  border-radius: 7px;
  border: none;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  text-align: center;
  line-height: 14px;
  font-weight: 800;
  letter-spacing: 0.06em;
  cursor: pointer;
  text-transform: uppercase;
  transition: filter 0.6s ease-in-out;
  width: ${props => (props.$fill ? "100%" : null)};
  min-width: ${props => (props.$icon ? "150px" : null)};

  ${props =>
    props.direction &&
    css`
      flex-direction: ${props.direction};
    `}

  &:hover {
    filter: saturate(1.5) brightness(1.1);
  }

  &[disabled] {
    opacity: 0.8;
    cursor: default;
  }
`

const StyledLink = styled(Link)`
  ${buttonStyle}
`

const StyledAnchor = styled.a`
  ${buttonStyle}
`

const StyledButton = styled.button`
  ${buttonStyle}
`

const Gap = styled.div`
  width: 10px;
  flex: 0 0 10px;
`
const IconWrapper = styled.div`
  margin-top: -1px;
  display: flex;
  align-items: center;
`

const Button = props => {
  let icon = null
  if (props.icon) {
    switch (props.icon) {
      case "PlusIcon":
        icon = <PlusIcon />
        break
      case "ArrowLeft":
      default:
        icon = <Arrowleft />
        break
    }
    icon = (
      <>
        <Gap />
        <IconWrapper>{icon}</IconWrapper>
      </>
    )
  }

  if (props.submit) {
    return (
      <StyledButton
        type="submit"
        onClick={props.onClick}
        $fill={props.fill}
        style={props.style}
        direction={props.direction}
        $small={props.small}
        disabled={props.disabled}
        data-cy={props["data-cy"]}
      >
        {props.label}
        {icon}
      </StyledButton>
    )
  }

  if (props.to) {
    return (
      <StyledLink
        to={props.to}
        $fill={props.fill}
        style={props.style}
        direction={props.direction}
        $small={props.small}
        data-cy={props["data-cy"]}
      >
        {props.label}
        {icon}
      </StyledLink>
    )
  }

  if (props.href) {
    return (
      <StyledAnchor
        href={props.href}
        $fill={props.fill}
        style={props.style}
        direction={props.direction}
        $small={props.small}
        target="_blank"
        rel="noreferrer"
        data-cy={props["data-cy"]}
      >
        {props.label}
        {icon}
      </StyledAnchor>
    )
  }

  return (
    <StyledButton
      type="button"
      onClick={props.onClick}
      $fill={props.fill}
      style={props.style}
      direction={props.direction}
      $small={props.small}
      disabled={props.disabled}
      data-cy={props["data-cy"]}
    >
      {props.label}
      {icon}
    </StyledButton>
  )
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string,
  submit: PropTypes.bool,
  href: PropTypes.string,
  fill: PropTypes.bool,
  icon: PropTypes.oneOf(["ArrowLeft", "PlusIcon", null]),
  direction: PropTypes.oneOf(["row-reverse", "row"]),
  small: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  fill: false,
  icon: null,
  submit: false,
  small: false,
  disabled: false,
}

export default Button
