import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../../theme"

const StyledSelect = styled.select`
  background: rgba(255, 255, 255, 0.9);
  opacity: 0.75;
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3),
    -1px -1px 2px rgba(205, 219, 236, 0.5),
    inset -5px 5px 10px rgba(168, 181, 214, 0.2),
    inset 5px 5px 13px rgba(220, 231, 253, 0.7);
  backdrop-filter: blur(40px);
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='30' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position: right center;
  border-radius: 4px;
  font-size: 16px;
  padding-left: 15px;
  line-height: 12px;
  letter-spacing: 0.055em;
  border: none;
  height: 35px;
  width: 100%;
  margin: ${props => props.margin};
  -webkit-appearance: none;

  @media (min-width: ${breakpoints.medium}px) {
    font-size: 12px;
  }

  &:focus {
    color: rgba(4, 6, 44, 1);
    opacity: 1;
    outline: none;
  }

  &[readonly] {
    cursor: not-allowed;
    background-color: #ddd;
  }
`

const Input = ({ field, form, ...props }) => {
  return (
    <StyledSelect {...field} {...props} data-cy={field?.name || props?.name}>
      {props.children}
    </StyledSelect>
  )
}

export default Input
