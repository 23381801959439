import React from "react"
import PropTypes from "prop-types"
import { Image } from "grommet"
import styled from "styled-components"

const defaultImage =
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjYiIGhlaWdodD0iMjYiIHZpZXdCb3g9IjAgMCAyNiAyNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjI2IiBoZWlnaHQ9IjI2IiBmaWxsPSJ3aGl0ZSIvPgo8ZWxsaXBzZSBjeD0iMTMiIGN5PSI5Ljc2MDMyIiByeD0iMy43NDEyMSIgcnk9IjMuNjU0IiBmaWxsPSIjNjA4MEU5Ii8+CjxwYXRoIGQ9Ik0xOS4yMzU0IDE5LjkxMDNDMTMuODkwOCAxOS45MTAzIDExLjIxODUgMTkuOTEwMyA2Ljc2NDY1IDE5LjkxMDNDNi43NjQ2NSAxNi43NzExIDkuNTU2MzEgMTQuMjI2MyAxMyAxNC4yMjYzQzE2LjQ0MzcgMTQuMjI2MyAxOS4yMzU0IDE2Ljc3MTEgMTkuMjM1NCAxOS45MTAzWiIgZmlsbD0iIzYwODBFOSIvPgo8L3N2Zz4K"

const Border = styled.div`
  display: inline-block;
  background: linear-gradient(135deg, #f3f7ff 0%, #ededed 100%);
  padding: ${props =>
    props.size === "small" ? "0px" : props.size === "medium" ? "3px" : "11px"};
  border-radius: ${props =>
    props.size === "small" ? "7px" : props.size === "medium" ? "10px" : "11px"};
  box-shadow: ${props =>
    props.size === "small"
      ? "0px 4px 6px rgba(0, 0, 0, 0.25)"
      : props.size === "medium"
      ? null
      : "-11px 11px 22px rgba(189, 192, 220, 0.2), 11px -11px 22px rgba(195, 198, 225, 0.2), -11px -11px 22px rgba(255, 255, 255, 0.9), 11px 11px 28px rgba(212, 213, 226, 0.9), inset 1px 1px 2px rgba(255, 255, 255, 0.3), inset -1px -1px 2px rgba(199, 203, 225, 0.5)"};
`

const StyledImage = styled(Image)`
  border: ${props =>
    props.size === "small"
      ? "1.5px solid #FFFFFF"
      : props.size === "medium"
      ? "2px solid #FFFFFF"
      : null};
  width: ${props =>
    props.size === "small"
      ? "33px"
      : props.size === "medium"
      ? "50px"
      : "100px"};
  height: ${props =>
    props.size === "small"
      ? "33px"
      : props.size === "medium"
      ? "50px"
      : "100px"};
  display: block;
  object-fit: cover;
  border-radius: ${props =>
    props.size === "small" ? "7px" : props.size === "medium" ? "10px" : "3px"};
`

const Avatar = ({ image, alt, size, ...rest }) => {
  let source = image
  if (!image) {
    source = defaultImage
  }
  return (
    <Border size={size}>
      <StyledImage size={size} src={source} alt={alt} {...rest} />
    </Border>
  )
}

export default Avatar

Avatar.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  alt: PropTypes.string.isRequired,
}

Avatar.defaultProps = {
  size: "small",
  border: "small",
  alt: "alt, dot at the end.",
}
