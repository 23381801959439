import React from "react"
import { LAMPORT_MULTIPLIER } from "../../../utils/oyster/common/src/index.tsx"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { Image } from "@bw/bits"
import { Like } from "@bw/icons"
import styled, { css } from "styled-components"
import { useTranslation } from "react-i18next"
import { breakpoints } from "../../../theme"

const Card = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  background: transparent;
  padding: 0 24px 24px;
  max-width: 240px;

  &:before {
    content: "";
    position: absolute;
    top: 80px;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      135deg,
      rgba(228, 232, 244, 0.85) 0%,
      rgba(255, 255, 255, 0.85) 100%
    );
    box-shadow: -15px 15px 30px rgba(218, 225, 243, 0.2),
      15px -15px 30px rgba(218, 224, 240, 0.2),
      -15px -15px 30px rgba(255, 255, 255, 0.9),
      15px 15px 38px rgba(232, 234, 244, 0.9),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(219, 224, 241, 0.5);
    border-radius: 21px;
    backdrop-filter: blur(30px);
  }
`

const smallCardVariant = css`
  width: 100px;
  height: 100px;
  margin: 0 auto;

  @media (min-width: ${breakpoints.small}px) {
    width: 140px;
  }
`

const CardImage = styled.figure`
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;

  ${props => (props.variant ? smallCardVariant : "")}
`

const Content = styled.div`
  position: relative;
  color: #04062c;
  text-align: center;
  color: #000000;
  margin: ${props => (props.variant ? "14px 0 0;" : "24px 0 0;")};
`

const Title = styled.h4`
  font-weight: 900;
  font-size: 16px;
  line-height: 19px;
  margin: 0 0 5px;
`

const Subtitle = styled.div`
  font-size: 10px;
  line-height: 12px;
  font-weight: 700;
  margin: 5px 0;

  span {
    color: #04062c;
    opacity: 0.5;
  }
`

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;
`

const CardInfos = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 20px;
  color: #6080e9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  small {
    font-size: 11px;
    color: #aebef3;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 15px;
`

const NftTeaser = ({
  to,
  image,
  name,
  artist,
  price,
  likes,
  variant,
  button,
  state,
}) => {
  const { t } = useTranslation()

  let ImageComponent = image

  if (typeof image === "string") {
    ImageComponent = <Image image={image} type={variant ? "Small" : "NFT"} />
  }

  if (to && button) {
    // if we have a button, it needs to be clickable. Then, we place the link
    // on the image only
    ImageComponent = <Link {...{ to, state }}>{ImageComponent}</Link>
  }

  const teaser = (
    <Card>
      <CardImage variant={variant}>{ImageComponent}</CardImage>
      <Content variant={variant}>
        <Title>{name}</Title>
        <Subtitle>
          {t("nft.from")} <span>{artist}</span>
        </Subtitle>
        <Grid>
          <CardInfos>
            {price ? (
              <>
                {Number.isSafeInteger(price) && parseInt(price) > 9999
                  ? price / LAMPORT_MULTIPLIER
                  : price}
                <small>SOL</small>
              </>
            ) : (
              <>
                &nbsp;
                <small>{t("nft.notListed")}</small>
              </>
            )}
          </CardInfos>
          <CardInfos>
            {typeof likes !== "undefined" && (
              <>
                <Like strokeColor="#6080E9" />
                <small>{likes}</small>
              </>
            )}
          </CardInfos>
        </Grid>
        {button && <ButtonWrapper>{button}</ButtonWrapper>}
      </Content>
    </Card>
  )

  if (to && !button) {
    return <Link {...{ to, state }}>{teaser} </Link>
  }

  return teaser
}

export default NftTeaser

NftTeaser.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  artist: PropTypes.string,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  to: PropTypes.string,
  likes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variant: PropTypes.bool,
  button: PropTypes.element,
  state: PropTypes.object,
}
