import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import theme, { mediaQuery } from "../../../theme.js"

const StyledTitle = styled.h1`
  color: ${theme.global.colors.primary};
  font-size: 24px;
  line-height: 1.2;
  font-weight: 800;
  margin: 0 0 0.3em;

  ${mediaQuery("medium")`
    font-size: 28px;  
  `}
`

const Subtitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  line-height: 1.2;
  color: #000;

  ${mediaQuery("medium")`
  font-size: 18px;
  `}
`

const Title = ({ suptitle, title, subtitle }) => {
  return (
    <div>
      {suptitle && <Subtitle>{suptitle}</Subtitle>}
      <StyledTitle>{title}</StyledTitle>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </div>
  )
}

export default Title

Title.propTypes = {
  suptitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
}
