import React from "react"
import styled from "styled-components"
import { ErrorMessage } from "formik"

const StyledError = styled.div`
  color: red;
  margin-top: 2px;
  font-size: 12px;
  line-height: 14px;
`

const Error = ({ name }) => (
  <ErrorMessage name={name}>
    {message => {
      if (typeof message !== "string") {
        return null
      }
      return <StyledError>{message}</StyledError>
    }}
  </ErrorMessage>
)

export default Error
