import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
  display: inline-block;

  &:after {
    background: linear-gradient(
      239.97deg,
      ${props => props.colors[0]} 24.3%,
      ${props => props.colors[1]} 62.81%,
      ${props => props.colors[2]} 96.78%
    );
    z-index: 1;
    filter: blur(16px);
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    display: block;
    opacity: 0.65;
    left: 20px;
    right: 20px;
    height: 80px;
    content: "";
    transform: translateY(27px);
  }
`

const ZIndex = styled.div`
  z-index: 3;
  position: relative;
`

const Shadow = ({ colors, children }) => {
  return (
    <Wrapper colors={colors}>
      <ZIndex>{children}</ZIndex>
    </Wrapper>
  )
}

export default Shadow

Shadow.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string),
}

Shadow.defaultProps = {
  colors: ["#39ABF0", "#8879ED", "#D569FA"],
}
