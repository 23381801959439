import React from "react"
import PropTypes from "prop-types"
import { Box } from "grommet"
import { Link } from "gatsby"
import styled from "styled-components"
import CarouselArrowIcon from "../carouselArrow/arrow-carousel"

const RowReverse = styled(Box)`
  transform: ${props => (props.type === "prev" ? "scaleX(-1)" : "none")};
`

const StyledLink = styled(Link)`
  font-weight: 800;
  font-size: 14px;
  color: ${props => props.color};
  display: flex;
  line-height: 17px;
  align-items: center;
  gap: 10px;
  flex-direction: ${props => (props.type === "next" ? "row" : "row-reverse")};
`

const ArticleButton = ({ color, to, label, type }) => {
  return (
    <StyledLink to={to} color={color} type={type}>
      {label}
      <RowReverse type={type}>
        <CarouselArrowIcon />
      </RowReverse>
    </StyledLink>
  )
}

export default ArticleButton

ArticleButton.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["prev", "next"]),
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  color: PropTypes.string,
}

ArticleButton.defaultProps = {
  type: "prev",
  color: "#04062C",
}
