import React from "react"

const ArrowDropdown = () => (
  <svg
    width="4"
    height="8"
    viewBox="0 0 4 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4 4L0 8L-3.49691e-07 0L4 4Z" fill="#04062C" />
  </svg>
)

export default ArrowDropdown
