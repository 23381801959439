import React from "react"
import PropTypes from "prop-types"

const IconInstagram = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.55556 2C4.49222 2 2 4.49222 2 7.55556V16.4444C2 19.5078 4.49222 22 7.55556 22H16.4444C19.5078 22 22 19.5078 22 16.4444V7.55556C22 4.49222 19.5078 2 16.4444 2H7.55556ZM7.55556 4.22222H16.4444C18.2822 4.22222 19.7778 5.71778 19.7778 7.55556V16.4444C19.7778 18.2822 18.2822 19.7778 16.4444 19.7778H7.55556C5.71778 19.7778 4.22222 18.2822 4.22222 16.4444V7.55556C4.22222 5.71778 5.71778 4.22222 7.55556 4.22222ZM17.5556 5.33333C17.2609 5.33333 16.9783 5.4504 16.7699 5.65877C16.5615 5.86714 16.4444 6.14976 16.4444 6.44444C16.4444 6.73913 16.5615 7.02174 16.7699 7.23012C16.9783 7.43849 17.2609 7.55556 17.5556 7.55556C17.8502 7.55556 18.1329 7.43849 18.3412 7.23012C18.5496 7.02174 18.6667 6.73913 18.6667 6.44444C18.6667 6.14976 18.5496 5.86714 18.3412 5.65877C18.1329 5.4504 17.8502 5.33333 17.5556 5.33333ZM12 6.44444C8.93667 6.44444 6.44444 8.93667 6.44444 12C6.44444 15.0633 8.93667 17.5556 12 17.5556C15.0633 17.5556 17.5556 15.0633 17.5556 12C17.5556 8.93667 15.0633 6.44444 12 6.44444ZM12 8.66667C13.8378 8.66667 15.3333 10.1622 15.3333 12C15.3333 13.8378 13.8378 15.3333 12 15.3333C10.1622 15.3333 8.66667 13.8378 8.66667 12C8.66667 10.1622 10.1622 8.66667 12 8.66667Z"
      fill={fillColor}
    />
  </svg>
)

export default IconInstagram

IconInstagram.propsTypes = {
  fillColor: PropTypes.string,
}

IconInstagram.defaultProps = {
  fillColor: "#6080E9",
}
