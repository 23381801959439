import React from "react"

const Private = () => (
  <svg
    width="10"
    height="13"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.26226 0.0169971C3.97454 0.0525948 3.51327 0.175536 3.2589 0.284436C2.752 0.501424 2.38224 0.758301 1.99067 1.16546C1.35844 1.8229 0.98629 2.61141 0.837932 3.60794C0.794413 3.90041 0.788802 4.08325 0.7887 5.21316L0.788573 6.48827L0.647808 6.55487C0.351677 6.69495 0.0939618 7.015 0.029622 7.32256C-0.0101143 7.51245 -0.00975889 11.9442 0.0300028 12.1316C0.0901786 12.4151 0.307395 12.7102 0.555335 12.8451C0.834149 12.9968 0.629247 12.9898 4.74849 12.9895C7.86227 12.9892 8.54282 12.9831 8.65078 12.9543C9.04449 12.8495 9.34633 12.5262 9.42103 12.1294C9.43723 12.0433 9.44678 11.1438 9.44678 9.70356C9.44678 7.20893 9.44972 7.26408 9.30192 6.99253C9.21059 6.8247 9.02146 6.6543 8.82402 6.56191L8.65967 6.48497L8.65916 5.21151C8.65865 3.86946 8.64489 3.64554 8.53396 3.17285C8.14835 1.5299 7.01385 0.371501 5.48843 0.063208C5.20149 0.00521589 4.55456 -0.0191591 4.26226 0.0169971ZM5.21924 1.52913C5.60545 1.60135 5.98883 1.80061 6.29803 2.08986C6.70619 2.47168 6.97408 2.98041 7.11703 3.64511C7.16467 3.86664 7.17002 3.99682 7.1799 5.17581L7.19072 6.46438H4.72412H2.25752L2.26834 5.17581C2.27822 3.99682 2.28357 3.86664 2.33121 3.64511C2.47073 2.99636 2.74027 2.47587 3.12449 2.11342C3.34416 1.90621 3.48442 1.81051 3.74219 1.69194C4.20935 1.47703 4.66943 1.42633 5.21924 1.52913ZM5.01525 9.00044C5.49465 9.21251 5.63618 9.8426 5.29414 10.2422C5.24148 10.3037 5.13644 10.3805 5.03993 10.428C4.90277 10.4955 4.8461 10.509 4.69873 10.509C4.23995 10.509 3.88895 10.1347 3.91779 9.67629C3.95235 9.12737 4.5166 8.77988 5.01525 9.00044Z"
      fill="#04062C"
    />
  </svg>
)

export default Private
