import React from "react"
import { useTranslation } from "react-i18next"
import CookieConsent from "react-cookie-consent"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"
import { useLocation } from "@reach/router"

const CookieBanner = () => {
  const location = useLocation()
  const { t } = useTranslation()

  return (
    <CookieConsent
      location="bottom"
      buttonText={t("cookies.accept")}
      enableDeclineButton
      declineButtonText={t("cookies.deny")}
      onAccept={() => {
        initializeAndTrack(location)
      }}
      cookieName="gatsby-gdpr-google-analytics"
      style={{
        padding: "15px",
        left: "auto",
        right: "10px",
        marginBottom: "10px",
        borderRadius: "15px",
        width: "300px",
        background: "linear-gradient(264.34deg,#04062c 4.39%,#101461 100%)",
        justifyContent: "space-between",
      }}
      contentStyle={{ display: "block", flex: "auto", margin: "0 0 15px" }}
      declineButtonStyle={{
        fontWeight: 700,
        background: "transparent",
        color: "#fff",
        textTransform: "uppercase",
        fontSize: "13px",
        margin: 0,
      }}
      buttonStyle={{
        fontWeight: 700,
        borderRadius: "7px",
        background:
          "linear-gradient( 264.34deg,rgba(255,255,255,0.35) 4.39%,rgba(218,220,251,0.35) 46.5%,rgba(162,166,244,0.35) 100% )",
        color: "#fff",
        textTransform: "uppercase",
        fontSize: "13px",
        margin: "0 0 0 15px",
      }}
      expires={150}
    >
      {t("cookies.notice")}
    </CookieConsent>
  )
}

export default CookieBanner
