import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { motion } from "framer-motion"

const Layer = styled(motion.div)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: rgba(4, 6, 44, 0.6);
  z-index: 50;
  justify-content: center;
  align-items: center;
`

const Close = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
`

const ModalElement = styled.div`
  position: relative;
  padding: 45px;
  min-height: 20px;
  background-color: #fff;
  color: #04062c;
  border-radius: 6px;
  box-shadow: 0px 10px 25px rgba(83, 120, 255, 0.2);
  text-align: start;
`

const variants = {
  open: { opacity: 1, y: 0, display: "flex" },
  closed: { opacity: 0, y: "-40px", transitionEnd: { display: "none" } },
}

const Modal = ({ state, children }) => {
  const [show, setShow] = state
  const modalRef = React.useRef(null)

  if (typeof window === "undefined") {
    return null
  }

  const portal = document.getElementById("modalContainer")

  return (
    portal &&
    ReactDOM.createPortal(
      <Layer
        ref={modalRef}
        animate={show ? "open" : "closed"}
        variants={variants}
        initial={false}
        onClick={e => {
          if (e.target !== modalRef.current) {
            return false
          }
          setShow(false)
        }}
      >
        <ModalElement>
          <Close
            role="button"
            tabIndex="0"
            onKeyDown={() => {
              setShow(false)
            }}
            onClick={() => {
              setShow(false)
            }}
          >
            <svg aria-label="Close" viewBox="0 0 24 24" height="24" width="24">
              <path
                fill="none"
                stroke="#04062C"
                strokeWidth="2"
                d="m3 3 18 18M3 21 21 3"
              />
            </svg>
          </Close>
          {children}
        </ModalElement>
      </Layer>,
      portal
    )
  )
}

export default Modal
