import React from "react"
import PropTypes from "prop-types"
import { Twitter, Instagram, Discord } from "@bw/icons"
import styled from "styled-components"

const StyledGrid = styled.div`
  display: flex;
  flex-direction: ${props => (props.$displayNames ? "column" : "row")};
  gap: ${props => props.$gap};
`
const StyledSocialLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  text-decoration: none;
  gap: ${props => props.$gap};
  background: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 135.5%;
  color: #04062c;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;

  :hover {
    opacity: 0.8;

    svg {
      opacity: 0.8;
    }
  }

  svg {
    opacity: 1;
    transition: opacity 0.4s ease-in-out;
    width: 24px;
    height: 24px;
  }
`

const Share = ({
  fillColorInsta,
  fillColorTwitter,
  fillColorDiscord,
  displayNames,
  gap,
}) => {
  return (
    <StyledGrid $displayNames={displayNames} $gap={gap}>
      <StyledSocialLink
        href="https://twitter.com/nft4artists"
        target="_blank"
        rel="noreferrer"
        $gap={gap}
      >
        {displayNames && <span>Twitter</span>}
        <Twitter fillColor={fillColorTwitter} />
      </StyledSocialLink>
      <StyledSocialLink
        href="https://www.instagram.com/nft4artists.io/"
        target="_blank"
        rel="noreferrer"
        $gap={gap}
      >
        {displayNames && <span>Instagram</span>}
        <Instagram fillColor={fillColorInsta} />
      </StyledSocialLink>
      <StyledSocialLink
        href="https://discord.gg/FJFdzpHRGw"
        target="_blank"
        rel="noreferrer"
        $gap={gap}
      >
        {displayNames && <span>Discord</span>}
        <Discord fillColor={fillColorDiscord} />
      </StyledSocialLink>
    </StyledGrid>
  )
}

export default Share

Share.propTypes = {
  fillColorTwitter: PropTypes.string,
  fillColorInsta: PropTypes.string,
  fillColorDiscord: PropTypes.string,
  displayNames: PropTypes.bool,
  gap: PropTypes.string,
}

Share.defaultProps = {
  fillColorTwitter: "#04062C",
  fillColorInsta: "#04062C",
  fillColorDiscord: "#04062C",
  displayNames: false,
  gap: "15px",
}
