import React from "react"
import { useTranslation } from "react-i18next"
import { Section, Button } from "@bw/bits"
import parse from "html-react-parser"

const BecomeAnArtist = () => {
  const { t } = useTranslation()
  return (
    <Section title={t("becomeAnArtist.title")}>
      {parse(t("becomeAnArtist.content"))}
      <Button
        label={t("becomeAnArtist.label")}
        href={t("becomeAnArtist.url")}
      />
    </Section>
  )
}

export default BecomeAnArtist
