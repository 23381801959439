import React from "react"
import PropTypes from "prop-types"

const IconTwitch = ({ fillColor }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.1822 7.21824H11.9995V12.4349H10.1822V7.21824Z"
      fill={fillColor}
    />
    <path
      d="M16.9984 7.21824H15.1802V12.4349H16.9984V7.21824Z"
      fill={fillColor}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 5.47917V19.3908H6.99891V22H9.72836L12.4535 19.39H16.5446L22 14.175V2H3.36255L2 5.47917ZM5.18073 3.7375H20.1818V13.3033L16.9993 16.3467H11.9987L9.2736 18.9525V16.3467H5.18073V3.7375Z"
      fill={fillColor}
    />
  </svg>
)

export default IconTwitch

IconTwitch.propsTypes = {
  fillColor: PropTypes.string,
}

IconTwitch.defaultProps = {
  fillColor: "#6080E9",
}
